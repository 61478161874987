<template>
  <div>
    <a
      v-if="item.download || item?.href"
      :class="[
        $page.component.startsWith(item.component)
          ? 'bg-slate-950 text-white'
          : 'text-slate-400 hover:bg-slate-800 hover:text-white',
        indent ? 'pl-14' : '',
        item?.class || '',
        'group flex items-center rounded-md px-2 py-2 text-sm font-medium test',
      ]"
      :download="item.download"
      :href="href"
      :target="item?.target"
    >
      <component
        :is="item.icon"
        :class="[
          $page.component.startsWith(item.component) ? 'text-slate-400' : 'text-slate-500 group-hover:text-slate-400',
          'mr-3 h-5 w-5 flex-shrink-0',
        ]"
        aria-hidden="true"
      />
      <span class="flex flex-col">
        {{ item.name }}
        <small v-if="item?.subName" class="" v-text="item.subName"></small>
      </span>
      <span v-if="item.external" class="ml-auto">
        <ArrowTopRightOnSquareIcon aria-hidden="true" class="h-4 w-4 text-slate-500 group-hover:text-slate-300" />
      </span>
      <a v-if="item.email" :href="href" class="ml-auto">
        <EnvelopeIcon aria-hidden="true" class="h-4 w-4 text-slate-500 group-hover:text-slate-300" />
      </a>
    </a>
    <div v-else-if="item.action">
      <button
        :class="[
          $page.component.startsWith(item.component)
            ? 'bg-slate-950 text-white'
            : 'text-slate-400 hover:bg-slate-800 hover:text-white',
          indent ? 'pl-14' : '',
          'group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium',
          item?.class || '',
          item.disabled ? 'opacity-50 pointer-events-none' : '',
        ]"
        @click="item.action"
      >
        <component
          :is="item.icon"
          :class="[
            $page.component.startsWith(item.component) ? 'text-slate-400' : 'text-slate-500 group-hover:text-slate-400',
            'mr-3 h-5 w-5 flex-shrink-0',
          ]"
          aria-hidden="true"
        />
        <span class="flex flex-col">
          {{ item.name }}
          <small v-if="item?.subName" class="" v-text="item.subName"></small>
        </span>
      </button>
    </div>
    <Link
      v-else
      :class="[
        $page.component.startsWith(item.component)
          ? 'bg-slate-950 text-white'
          : 'text-slate-400 hover:bg-slate-800 hover:text-white',
        indent ? 'pl-14' : '',
        'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
        item?.class || '',
        item.disabled ? 'opacity-50 pointer-events-none' : '',
      ]"
      :href="href"
      :target="item?.target"
    >
      <component
        :is="item.icon"
        :class="[
          $page.component.startsWith(item.component) ? 'text-slate-400' : 'text-slate-500 group-hover:text-slate-400',
          'mr-3 h-5 w-5 flex-shrink-0',
        ]"
        aria-hidden="true"
      />
      <span class="flex flex-col">
        {{ item.name }}
        <small v-if="item?.subName" class="" v-text="item.subName"></small>
      </span>
    </Link>
  </div>
</template>

<script setup>
import { toRefs, ref, inject } from "vue";
import { Link } from "@inertiajs/vue3";
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/20/solid";
import { EnvelopeIcon } from "@heroicons/vue/24/outline";

const layoutState = inject("layoutState");

const props = defineProps({
  item: { type: Object, required: true },
  indent: { type: Boolean, default: false },
});

const { item, indent } = toRefs(props);

const href = ref(item.value.route ? route(item.value.route, item.value?.params) : item.value?.href);
</script>
