<script setup>
import { UsersIcon } from "@heroicons/vue/24/outline";
import { Link, usePage } from "@inertiajs/vue3";
import { computed, provide, reactive, ref } from "vue";

const navigation = [
  // { name: "My Booth", route: "events.event.booth.show.index", component: "Events/Event/Booth/Index" },
  // { name: "Messages", action: () => state.toggleMessages(true) },
  // { name: "Analytics", route: "events.event.booth.analytics.index", component: "Events/Event/Booth/Analytics/Index" },
  // Not needed because they can get to support in other ways
  // { name: "Support", route: "events.event.settings.edit", component: "Events/Event/Settings" },
];

const hasVisitors = computed(() => visitors.value.length > 0);

// const state = reactive({
//   isMessagesOpen: false,
//   toggleMessages: (value) => {
//     state.isMessagesOpen = value;
//   },
// });
// provide("layoutState", state);

const visitors = ref([]);
const channel = `events.${usePage().props.event.id}.supplier.${usePage().props.supplier.id}.visitors`;

Echo.join(channel)
  .here((users) => {
    visitors.value = users.filter((v) => v.type === "member" || v.type === "admin");
  })
  .joining((user) => {
    if (user.type === "member" || user.type === "admin") {
      visitors.value.push(user);
    }
  })
  .leaving((user) => {
    visitors.value = visitors.value.filter(function (u) {
      return u.id !== user.id;
    });
  });
</script>

<template>
  <div class="flex h-full">
    <!-- <aside class="bg-slate-900/95 border-l border-slate-700/30 xl:fixed xl:flex xl:flex-col h-full hidden w-64">
      <div class="flex-1 flex flex-col overflow-y-auto">
        <nav class="flex flex-1 flex-col gap-y-5 px-4 py-6 sm:px-6">
          <ul class="flex flex-1 flex-col gap-y-7" role="list">
            <li>
              <ul class="-mx-2 space-y-1" role="list">
                <li v-for="item in navigation" :key="item.name">
                  <Link
                    v-if="item.route"
                    :class="[
                      $page.component.startsWith(item.component)
                        ? 'bg-slate-900 text-white'
                        : 'text-slate-400 hover:text-white hover:bg-slate-700/50',
                      'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium',
                    ]"
                    :href="route(item.route, { event: $page.props.event.id })"
                  >
                    {{ item.name }}
                  </Link>
                  <button
                    v-else
                    :class="[
                      $page.component.startsWith(item.component)
                        ? 'bg-slate-900 text-white'
                        : 'text-slate-400 hover:text-white hover:bg-slate-700/50',
                      'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium w-full',
                    ]"
                    @click="item.action"
                  >
                    {{ item.name }}
                  </button>
                </li>
              </ul>
            </li>
            <li class="mt-auto">
              <div class="-mx-2 flex text-xs font-semibold leading-6 text-slate-400">
                Visitors
                <span
                  aria-hidden="true"
                  class="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-slate-900 px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-white ring-1 ring-inset ring-slate-700"
                  v-text="visitors.length"
                ></span>
              </div>
              <ul
                class="-mx-6 mt-2 divide-y divide-slate-700/50 h-[31.3rem] overflow-hidden overflow-y-scroll"
                role="list"
              >
                <template v-if="hasVisitors">
                  <li
                    v-for="visitor in visitors"
                    :key="visitor.email"
                    class="relative flex justify-between gap-x-6 px-2 py-4 hover:bg-slate-700/50 sm:px-4"
                  >
                    <div class="flex items-center min-w-0 gap-x-4">
                      <span class="relative inline-block">
                        <img :src="visitor.imageUrl" alt="" class="h-8 w-8 flex-none rounded-full bg-slate-50" />
                        <span
                          :class="[visitor.status === 'active' ? 'bg-green-400' : 'bg-slate-500']"
                          class="absolute bottom-0 right-0 block h-2 w-2 rounded-full ring-2 ring-slate-800"
                        />
                      </span>
                      <div class="min-w-0 flex-auto">
                        <p class="text-sm leading-6 text-slate-50">
                          <a :href="visitor.href">
                            <span class="absolute inset-x-0 -top-px bottom-0" />
                            {{ visitor.name }}
                          </a>
                        </p>
                        <p class="flex text-xs leading-5 text-slate-500">
                          <span class="relative truncate">{{ visitor.company }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="flex shrink-0 items-center gap-x-4">
                      <div class="hidden sm:flex sm:flex-col sm:items-end">
                        <p class="text-sm leading-6 text-slate-900">{{ person.role }}</p>
                        <p v-if="person.lastSeen" class="mt-1 text-xs leading-5 text-slate-500">
                          Last seen
                          <time :datetime="person.lastSeenDateTime">{{ person.lastSeen }}</time>
                        </p>
                        <div class="flex items-center gap-x-1.5">
                          <div class="flex-none rounded-full bg-emerald-500/20 p-1">
                            <div class="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                          </div>
                          <p class="text-xs leading-5 text-slate-500">Online</p>
                        </div>
                      </div>
                      <ChevronRightIcon aria-hidden="true" class="h-5 w-5 flex-none text-slate-400" />
                    </div>
                  </li>
                </template>
                <template v-else>
                  <div class="text-center py-8">
                    <UsersIcon class="mx-auto h-6 w-6 text-slate-400" />
                    <h3 class="mt-2 text-sm font-semibold text-slate-50">No visitors</h3>
                    <p class="mt-1 text-sm text-slate-400">Check back soon.</p>
                  </div>
                </template>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside> -->
    <!-- <div class="flex-1 h-full overflow-auto xl:pl-64"> -->
    <div class="flex-1 h-full overflow-auto">
      <slot></slot>
    </div>
  </div>
</template>
