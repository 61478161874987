import { useOnlineUserStore } from "@Stores/onlineUsersStore";
import { usePage } from "@inertiajs/vue3";
import AgoraRTC from "agora-rtc-sdk-ng";
import { computed, inject, markRaw, reactive, ref } from "vue";

export function useAgora() {
  const user = computed(() => usePage().props.auth.user);
  const toggleAgoraVideo = inject("toggleAgoraVideo");
  const resetMinimize = inject("resetMinimize");

  const appId = ref("fc181f89176c43c9a92b3f99cf6b7ee5");
  const sessionName = ref("");
  const cameraVideoPreset = ref("720p_2");
  const audioConfigPreset = ref("music_standard");
  const screenShareVideoPreset = ref("1080p_2");
  const client = ref(markRaw(AgoraRTC.createClient({ codec: "vp9", mode: "rtc" })));
  const localTracks = ref({
    uid: null,
    camera: {
      audio: null,
      video: null,
    },
    screen: {
      audio: null,
      video: null,
    },
  });
  const localTrackActive = ref({
    audio: false,
    video: false,
    screen: false,
  });
  const remoteUsers = reactive({});
  const mainStreamUid = ref(null);
  const LogLevel = {
    DEBUG: 0,
    INFO: 1,
    WARNING: 2,
    ERROR: 3,
    NONE: 4,
  };
  const localVideoContainer = ref(null);
  const fullScreenContainer = ref(null);
  const remoteVideoContainer = ref(null);
  const micToggleBtn = ref(null);
  const videoToggleBtn = ref(null);
  const shareScreenBtn = ref(null);
  const leaveChannelBtn = ref(null);
  const settingsBtn = ref(null);
  const helpBtn = ref(null);
  const devices = ref({});
  const overrodeMainUser = ref(false);
  const callTimeout = ref(null);
  const outgoingCallAudio = new Audio("/outgoing_call.mp3");
  const supplierNumber = ref(null);

  const { whisperUserBusy, whisperUserAvailable } = useOnlineUserStore();

  outgoingCallAudio.loop = true;

  AgoraRTC.enableLogUpload();
  AgoraRTC.setLogLevel(LogLevel.WARNING);

  const initAgora = async ({
    sessionName: _sessionName = undefined,
    localVideoContainer: _localVideoContainer = "agora-local-video-container",
    remoteVideoContainer: _remoteVideoContainer = "agora-remote-video-container",
    fullScreenContainer: _fullScreenContainer = "agora-full-screen-video-container",
    micToggleBtn: _micToggleBtn = "agora-mic-btn",
    videoToggleBtn: _videoToggleBtn = "agora-video-btn",
    shareScreenBtn: _shareScreenBtn = "agora-share-screen-btn",
    leaveChannelBtn: _leaveChannelBtn = "agora-leave-channel-btn",
    settingsBtn: _settingsBtn = "agora-settings-btn",
    helpBtn: _helpBtn = "agora-help-btn",
  } = {}) => {
    try {
      if (_sessionName == undefined) {
        console.error("Session name is required");
        return;
      }

      console.log("Initiating Agora", { _sessionName });
      sessionName.value = _sessionName;
      localVideoContainer.value = getEl(_localVideoContainer);
      remoteVideoContainer.value = getEl(_remoteVideoContainer);
      fullScreenContainer.value = getEl(_fullScreenContainer);
      micToggleBtn.value = getEl(_micToggleBtn);
      videoToggleBtn.value = getEl(_videoToggleBtn);
      shareScreenBtn.value = getEl(_shareScreenBtn);
      leaveChannelBtn.value = getEl(_leaveChannelBtn);
      settingsBtn.value = getEl(_settingsBtn);
      helpBtn.value = getEl(_helpBtn);

      await getDevices();

      console.log("Agora initiated");

      return { devices: devices.value };
    } catch (error) {
      console.error("Failed to initialize Agora", error);
    }
  };

  const getEl = (id) => {
    return document.getElementById(id);
  };

  const startVideoCall = async () => {
    // At a minimum they need to have an microphone.
    if (devices.value.audioDevices.length === 0) {
      console.error("No microphone devices found");
      return;
    }

    supplierNumber.value = sessionName.value.split(".")[1];

    addAgoraEventListeners();
    addLocalMediaControlListeners();
    localTracks.value.uid = await client.value.join(
      appId.value,
      sessionName.value,
      null,
      user.value.name + " - " + user.value.company_name
    );
    console.log("new user id", localTracks.value.uid);
    await initDevices();

    if (localTracks.value.camera.audio && localTracks.value.camera.video) {
      await client.value.publish([localTracks.value.camera.audio, localTracks.value.camera.video]);
    } else if (localTracks.value.camera.audio) {
      await client.value.publish(localTracks.value.camera.audio);
    }

    client.value.enableAudioVolumeIndicator();

    localTrackActive.value.audio = true;
    localTrackActive.value.video = true;
  };

  const callUser = async (caller, callee, supplier) => {
    // If the callee is busy, don't call them
    if (callee.isBusy) {
      return;
    }

    try {
      // Play the outgoing call audio
      if (!Object.keys(remoteUsers).length > 1) {
        outgoingCallAudio.play();
      }

      whisperUserBusy(user.value.id);

      // Make the call
      await axios.post(route("api.make-call.index"), {
        caller: caller.id,
        callee: callee.id,
        supplier: supplier ? supplier.id : supplierNumber.value,
        sessionName: sessionName.value,
      });

      // Join the video presence channel and listen for callAnswered
      Echo.private(`video.${sessionName.value}`).listenForWhisper("callAnswered", (e) => {
        // Clear the call timeout
        clearTimeout(callTimeout.value);
        // Stop the outgoing call audio
        outgoingCallAudio.pause();
        outgoingCallAudio.currentTime = 0;
      });
      // Start a 30 second timeout for the call for the caller
      callTimeout.value = setTimeout(() => {
        // This will work for the caller, but not the callee
        if (remoteUsers.value?.length === 0) leaveCall(caller);
      }, 30000);
    } catch (error) {
      console.log("callUser Error", error);
    }
  };

  const getDevices = async () => {
    let tempCameras = null;
    let tempMics = null;

    try {
      tempCameras = await AgoraRTC.getCameras();
    } catch (error) {
      console.error("Failed to get cameras", error);
    }

    try {
      tempMics = await AgoraRTC.getMicrophones();
    } catch (error) {
      console.error("Failed to get microphones", error);
    }

    devices.value = {
      audioDevices: tempMics,
      videoDevices: tempCameras,
    };

    console.log("getDevices", devices.value);

    return devices.value;
  };

  const setCameraDevice = async (event) => {
    console.log("setCameraDevice", event.target.value);

    let cams = await AgoraRTC.getCameras();
    let currentCam = cams.find((d) => d.label === localTracks.value.camera.video?.getTrackLabel());
    if (currentCam?.deviceId !== event.target.value) {
      if (currentCam) client.value.unpublish(localTracks.value.camera.video);

      localTracks.value.camera.video = await AgoraRTC.createCameraVideoTrack({
        cameraId: event.target.value,
        encoderConfig: cameraVideoPreset.value,
      });

      const localVideoElement = localVideoContainer.value.querySelector("video");

      if (localVideoElement) {
        localVideoElement.srcObject = new MediaStream([localTracks.value.camera.video.getMediaStreamTrack()]);
        localVideoElement.play();
      } else {
        createVideoElement();
      }

      if (client.value.connectionState === "DISCONNECTED")
        await client.value.join(appId.value, sessionName.value, null, null);

      client.value.publish(localTracks.value.camera.video);
      localTrackActive.value.video = true;
      console.log(`New camera track set with device ID ${event.target.value}`, localTrackActive.value.video);
    }
  };

  const setAudioDevice = async (event) => {
    console.log("setAudioDevice", event.target.value);

    if (!localTracks.value.camera.audio) {
      localTracks.value.camera.audio = await AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: audioConfigPreset.value,
        microphoneId: event.target.value,
      });
    } else {
      localTracks.value.camera.audio.setDevice(event.target.value);
    }

    localTrackActive.value.audio = true;
  };

  const initDevices = async () => {
    try {
      if (devices.value.videoDevices?.length > 0) {
        localTracks.value.camera.video = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: cameraVideoPreset.value,
        });
      }
    } catch (error) {
      console.error("Failed to create camera video track", error);
    }

    try {
      if (devices.value.audioDevices?.length > 0) {
        localTracks.value.camera.audio = await AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: audioConfigPreset.value,
        });
      }
    } catch (error) {
      console.error("Failed to create microphone audio track", error);
    }

    console.log("initDevices", localTracks.value);

    if (localTracks.value.camera.video) {
      createVideoElement();
    }
  };

  const createVideoElement = () => {
    const videoFromStream = document.createElement("video");
    videoFromStream.id = "local-video-stream";
    videoFromStream.setAttribute("webkit-playsinline", "webkit-playsinline");
    videoFromStream.setAttribute("playsinline", "playsinline");

    videoFromStream.srcObject = new MediaStream([localTracks.value.camera.video.getMediaStreamTrack()]);
    videoFromStream.controls = false;
    videoFromStream.height = 300;
    videoFromStream.width = 500;
    localVideoContainer.value.appendChild(videoFromStream);

    videoFromStream.onloadedmetadata = () => {
      videoFromStream.play();
    };
  };

  const addAgoraEventListeners = () => {
    client.value.on("user-joined", handleRemoteUserJoined);
    client.value.on("user-left", handleRemoteUserLeft);
    client.value.on("user-published", handleRemoteUserPublished);
    client.value.on("user-unpublished", handleRemoteUserUnpublished);
    client.value.on("volume-indicator", handleVolumeMonitor);
  };

  const handleVolumeMonitor = async (volumes) => {
    // If we are sharing our screen or if a remote user is sharing their screen, we don't want to swap the main video
    if (localTracks.value.screen.video) return;

    let highestVolume = 0;
    let highestUid = null;

    volumes.forEach((volume) => {
      if (volume.level > highestVolume && volume.uid !== localTracks.value.uid) {
        highestVolume = volume.level;
        highestUid = volume.uid;
      }
    });

    if (highestUid && mainStreamUid.value !== highestUid && !overrodeMainUser.value && highestVolume > 40) {
      console.log("Swapping main video due to volume", highestUid);
      await swapMainVideo(highestUid);
    }
  };

  const sortRemoteUsers = () => {
    let sortedUsers = Object.values(remoteUsers).sort((a, b) => {
      return a.uid - b.uid;
    });

    remoteUsers.value = sortedUsers;
  };

  const handleRemoteUserJoined = async (user) => {
    const uid = user.uid;
    remoteUsers[uid] = user;
    sortRemoteUsers();
    console.log(`User ${uid} joined the channel`, remoteUsers);
  };

  const handleRemoteUserLeft = async (user, reason) => {
    const uid = user.uid;

    remoteUsers.value = delete remoteUsers[uid];

    if (Object.keys(remoteUsers).length === 1) {
      leaveCall(user);
    }
  };

  const handleRemoteUserPublished = async (user, mediaType) => {
    console.log("User published their media", { user: user, mediaType: mediaType });
    const uid = user.uid;
    await client.value.subscribe(user, mediaType);

    delete remoteUsers[uid]; // Doing this to force it to see a change since we're dealing with a external function
    remoteUsers[uid] = user;

    if (mediaType === "video") {
      if (mainIsEmpty()) {
        mainStreamUid.value = uid;
        user.videoTrack.play(fullScreenContainer.value.id);
      } else {
        await createRemoteUserDiv(uid);
        user.videoTrack.play(`remote-user-${uid}-video`);
      }
    } else if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };

  const handleRemoteUserUnpublished = async (user, mediaType) => {
    const uid = user.uid;
    delete remoteUsers[uid]; // Doing this to force it to see a change since we're dealing with a external function
    remoteUsers[uid] = user;

    console.log(`User ${uid} unpublished their ${mediaType}`);
    if (mediaType === "video") {
      if (uid === mainStreamUid.value) {
        console.log(`User ${uid} is the main uid`);
        const newMainUid = getNewUidForMainUser();
        await setNewMainVideo(newMainUid);
      } else {
        await removeRemoteUserDiv(uid);
      }
    }
  };

  const addLocalMediaControlListeners = () => {
    micToggleBtn.value.addEventListener("click", handleMicToggle);
    videoToggleBtn.value.addEventListener("click", handleVideoToggle);
    shareScreenBtn.value.addEventListener("click", handleScreenShare);
    leaveChannelBtn.value.addEventListener("click", handleLeaveChannel);
  };

  const handleMicToggle = async (event) => {
    const isTrackActive = localTrackActive.value.audio;
    await muteTrack(localTracks.value.camera.audio, isTrackActive, event.target);
    localTrackActive.value.audio = !isTrackActive;
  };

  const handleVideoToggle = async (event) => {
    const isTrackActive = localTrackActive.value.video;
    await muteTrack(localTracks.value.camera.video, isTrackActive, event.target);
    localTrackActive.value.video = !isTrackActive;
  };

  const muteTrack = async (track, mute, btn) => {
    if (!track) return;
    await track.setMuted(mute);

    btn.classList.toggle("media-active");
    btn.classList.toggle("muted");
  };

  const handleScreenShare = () => {
    if (localTrackActive.value.screen) {
      stopScreenShare();
    } else {
      startScreenShare();
    }
  };

  const startScreenShare = async () => {
    const screenTrack = await AgoraRTC.createScreenVideoTrack({ encoderConfig: screenShareVideoPreset.value }, "auto");
    if (screenTrack instanceof Array) {
      localTracks.value.screen.video = screenTrack[0];
      localTracks.value.screen.audio = screenTrack[1];
    } else {
      localTracks.value.screen.video = screenTrack;
    }

    // We do this as well as hiding the local video to save bandwidth
    if (!mainIsEmpty()) {
      await createRemoteUserDiv(mainStreamUid.value);
      remoteUsers[mainStreamUid.value].videoTrack.play(`remote-user-${mainStreamUid.value}-video`);
    }

    let tracks = [localTracks.value.screen.video];
    if (localTracks.value.screen.audio) {
      tracks = [localTracks.value.screen.video, localTracks.value.screen.audio];
    }

    videoToggleBtn.value.disabled = true;
    await muteTrack(localTracks.value.camera.video, true, videoToggleBtn.value);
    localTrackActive.value.video = false;

    await client.value.unpublish([localTracks.value.camera.video]);
    await client.value.publish(tracks);

    localTrackActive.value.screen = true;
    localTracks.value.screen.video.play(fullScreenContainer.value.id);

    localTracks.value.screen.video.on("track-ended", () => {
      stopScreenShare();
    });
  };

  const stopScreenShare = async () => {
    let tracks = [localTracks.value.screen.video];
    if (localTracks.value.screen.audio) {
      tracks = [localTracks.value.screen.video, localTracks.value.screen.audio];
    }
    await client.value.unpublish(tracks);

    localTracks.value.screen.video && localTracks.value.screen.video.close();
    localTracks.value.screen.audio && localTracks.value.screen.audio.close();
    await muteTrack(localTracks.value.camera.video, false, videoToggleBtn.value);

    localTrackActive.value.video = true;
    await client.value.publish(localTracks.value.camera.video);

    videoToggleBtn.value.disabled = false;
    localTrackActive.value.screen = false;
    fullScreenContainer.value.replaceChildren();

    if (mainStreamUid.value) {
      await setNewMainVideo(mainStreamUid.value);
    } else if (Object.keys(remoteUsers) > 0) {
      const newMainUid = getNewUidForMainUser();
      await setNewMainVideo(newMainUid);
    }
  };

  const leaveCall = async (caller) => {
    await handleLeaveChannel();
  };

  const handleLeaveChannel = async () => {
    outgoingCallAudio.pause();
    outgoingCallAudio.currentTime = 0;
    whisperUserAvailable(user.value.id);
    Echo.private(`video.${sessionName.value}`).whisper("userLeftChannel", user.value);
    clearTimeout(callTimeout.value);

    client.value.off("user-joined", handleRemoteUserJoined);
    client.value.off("user-left", handleRemoteUserLeft);
    client.value.off("user-published", handleRemoteUserPublished);
    client.value.off("user-unpublished", handleRemoteUserUnpublished);
    client.value.off("volume-indicator", handleVolumeMonitor);

    for (let trackName in localTracks.value.camera) {
      const track = localTracks.value.camera[trackName];
      if (track) {
        track.stop();
        track.close();
        localTracks.value.camera[trackName] = undefined;
      }
    }
    if (localTrackActive.value.screen) {
      let tracks = [localTracks.value.screen.video];
      localTracks.value.screen.video.close();
      if (localTracks.value.screen.audio) {
        tracks = [localTracks.value.screen.video, localTracks.value.screen.audio];
        localTracks.value.screen.audio.close();
      }

      await client.value.unpublish(tracks);
    }
    await client.value.leave();
    console.log("client left channel successfully");
    for (const flag in localTrackActive) {
      localTrackActive[flag] = false;
    }
    remoteVideoContainer.value.replaceChildren();
    fullScreenContainer.value.replaceChildren();

    toggleAgoraVideo();
    resetMinimize();
  };

  const createRemoteUserDiv = async (uid) => {
    console.log("remote video container", remoteVideoContainer);
    const containerDivId = document.getElementById(`remote-user-${uid}-container`);
    if (containerDivId) return;

    console.log(`add remote user div for uid: ${uid}`);
    const containerDiv = document.createElement("div");
    containerDiv.id = `remote-user-${uid}-container`;

    const remoteUserDiv = document.createElement("div");
    remoteUserDiv.id = `remote-user-${uid}-video`;
    remoteUserDiv.classList.add("remote-video");
    containerDiv.appendChild(remoteUserDiv);
    remoteVideoContainer.value.appendChild(containerDiv);

    containerDiv.addEventListener("dblclick", async (e) => {
      overrodeMainUser.value = true;
      await swapMainVideo(uid);
    });
  };

  const removeRemoteUserDiv = async (uid) => {
    const containerDiv = document.getElementById(`remote-user-${uid}-container`);
    if (containerDiv) {
      containerDiv.removeEventListener("dblclick", async (e) => {});
      containerDiv.parentNode.removeChild(containerDiv);
    }
  };

  const mainIsEmpty = () => {
    return fullScreenContainer.value.childNodes.length === 0;
  };

  const setNewMainVideo = async (newMainUid) => {
    if (!newMainUid) return;
    await removeRemoteUserDiv(newMainUid);
    console.log("setNewMainVideo", newMainUid);

    if (remoteUsers[newMainUid].videoTrack) remoteUsers[newMainUid].videoTrack.play(fullScreenContainer.value.id);

    mainStreamUid.value = newMainUid;
    console.log(`newMainUid: ${newMainUid}`);
  };

  const swapMainVideo = async (newMainUid) => {
    const mainStreamUser = remoteUsers[mainStreamUid.value];
    if (mainStreamUser) {
      await createRemoteUserDiv(mainStreamUid.value);
      const videoTrack = remoteUsers[mainStreamUid.value].videoTrack;
      if (videoTrack) {
        videoTrack.play(`remote-user-${mainStreamUid.value}-video`);
      }
    }
    await setNewMainVideo(newMainUid);
  };

  const getNewUidForMainUser = () => {
    console.log("getNewUidForMainUser", remoteUsers);

    let newUid = null;

    if (Object.keys(remoteUsers).length > 1) newUid = Object.values(remoteUsers)[1].uid;

    console.log("newMainUserUid", newUid);

    return newUid;
  };

  return {
    localTracks,
    localTrackActive,
    remoteUsers,

    initAgora,
    getDevices,
    setCameraDevice,
    setAudioDevice,
    startVideoCall,
    callUser,
    leaveCall,
  };
}
