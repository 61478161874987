<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="fixed inset-0 z-50 flex items-end p-4 pointer-events-none sm:p-6 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="isShowing"
          :class="[
            isSuccess
              ? 'border-green-300'
              : isError
              ? 'border-red-300'
              : isWarning
              ? 'border-yellow-300'
              : 'border-primary-300',
          ]"
          class="bg-white border max-w-sm w-full shadow-md rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon v-if="isSuccess" aria-hidden="true" class="h-6 w-6 text-green-400" />
                <ExclamationCircleIcon v-else-if="isError" aria-hidden="true" class="h-6 w-6 text-red-400" />
                <ExclamationTriangleIcon v-else-if="isWarning" aria-hidden="true" class="h-6 w-6 text-yellow-400" />
                <InformationCircleIcon v-else aria-hidden="true" class="h-6 w-6 text-primary-400" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-slate-900">{{ notification.title }}</p>
                <p class="mt-1 text-sm text-slate-500">{{ notification.message }}</p>
              </div>
              <!-- <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="dismiss"
                  class="bg-white rounded-md inline-flex text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useNotification } from "@Library";

const { isSuccess, isError, isWarning, isShowing, dismiss, notification } = useNotification();
</script>
