<script setup>
import { ref } from "vue";

const showAppRefresh = ref(false);
const refreshPage = () => {
  window.location.reload();
};

Echo.join("onlineUsers").listen("AppRefresh", (e) => {
  showAppRefresh.value = true;
});
</script>

<template>
  <div v-if="showAppRefresh" class="bg-slate-800/75 sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6 text-center">
      <h3 class="text-base font-semibold leading-6 text-white">Update available!</h3>
      <div class="mt-2 max-w-xl text-sm text-slate-500">
        <p>Please refresh the page to get the latest updates.</p>
      </div>
      <div class="mt-5">
        <button
          class="rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
          type="button"
          @click="refreshPage"
        >
          Refresh App
        </button>
      </div>
    </div>
  </div>
</template>
