<template>
  <div>
    <TransitionRoot :show="modelValue" as="template">
      <Dialog as="div" class="fixed inset-0 flex z-40" @close="close">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-slate-800 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-slate-900">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  type="button"
                  @click="close"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" class="h-6 w-6 text-white" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <PLogo class="h-10 w-10" fill1="fill-primary-700" fill2="fill-primary-600" fill3="fill-primary-700" />
              <div class="flex flex-col ml-4">
                <p class="text-lg font-semibold text-white leading-none">Mid-States</p>
                <p class="text-xs mt-0.5 text-slate-100/50 leading-none">{{ portalTitle }}</p>
              </div>
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <p-side-bar-navigation-item v-for="item in items" :key="item.name" :item="item" />
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div aria-hidden="true" class="flex-shrink-0 w-14">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div v-show="!hideSideBarForShow" class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-slate-900">
        <div class="flex items-center h-16 flex-shrink-0 px-4 bg-slate-800">
          <PLogo class="h-10 w-10" fill1="fill-primary-700" fill2="fill-primary-600" fill3="fill-primary-700" />
          <div class="flex flex-col ml-4">
            <p class="text-lg font-semibold text-white leading-none">Mid-States</p>
            <p class="text-xs mt-0.5 text-slate-200/50 leading-none">{{ portalTitle }}</p>
          </div>
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto">
          <nav class="flex-1 px-2 py-4 space-y-1">
            <p-side-bar-navigation-item v-for="item in items" :key="item.name" :item="item" />
          </nav>
          <div class="px-2 py-4">
            <AppRefresh></AppRefresh>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppRefresh from "@Components/AppRefresh.vue";
import { useStr } from "@Composables";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { usePage } from "@inertiajs/vue3";
import { PLogo } from "@Library";
import { computed, toRefs } from "vue";
import PSideBarNavigationItem from "./PSideBarNavigationItem.vue";

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  items: { type: Object, required: true },
  hideSideBarForShow: { type: Boolean, default: false },
});

const { capitalize } = useStr();

const user = computed(() => usePage().props.auth.user);

const portalTitle = computed(() => {
  return user.value.type === "vendor" ? "Supplier Portal" : `${capitalize(user.value.type)} Portal`;
});

const emit = defineEmits(["update:modelValue"]);
const { modelValue, items } = toRefs(props);

const close = () => {
  emit("update:modelValue", false);
};
</script>
