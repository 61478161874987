<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { PhoneIcon } from "@heroicons/vue/20/solid";
import { usePage } from "@inertiajs/vue3";
import { computed, ref } from "vue";

const emit = defineEmits(["incomingCall", "answerCall", "declineCall", "canceledSession", "leftCall"]);

const user = computed(() => usePage().props.auth.user);
const caller = ref(null);
const callee = ref(null);
const sessionName = ref(null);
const callTimeout = ref(null);

const open = ref(false);

const handleAnswerCall = () => {
  emit("answerCall", caller.value, callee.value, sessionName.value);
  open.value = false;
  clearTimeout(callTimeout.value);
};

const handleDeclineCall = () => {
  emit("declineCall", caller.value, callee.value, sessionName.value);
  open.value = false;
  clearTimeout(callTimeout.value);
};

// Listen for an private incoming call
Echo.private(`user.${user.value.id}`).listen("IncomingCall", (e) => {
  caller.value = e.caller;
  callee.value = e.callee;
  sessionName.value = e.sessionName;

  emit("incomingCall", e);
  open.value = true;

  // Set a timeout for the call for the callee
  callTimeout.value = setTimeout(() => {
    emit("declineCall", caller.value, callee.value, sessionName.value);
    open.value = false;
  }, 25000);

  // Listen for a canceled session
  Echo.private(`video.${sessionName.value}`).listenForWhisper("userLeftChannel", (e) => {
    console.log("User left channel", e);
    emit("leftCall", caller.value, callee.value, sessionName.value);
    open.value = false;
    clearTimeout(callTimeout.value);
  });
});

window.addEventListener("beforeunload", () => {
  sessionName.value = null;
  caller.value = null;
  callee.value = null;
  callTimeout.value = null;
});
</script>

<template>
  <div>
    <TransitionRoot :show="open" as="template">
      <Dialog as="div" class="relative z-20" @close="() => {}">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div>
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-slate-100">
                    <PhoneIcon aria-hidden="true" class="h-6 w-6 text-slate-600" />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6">Incoming call </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-slate-500">
                        You have an incoming call from <span class="font-semibold">{{ caller?.name }}</span
                        >.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    class="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
                    type="button"
                    @click="handleAnswerCall"
                  >
                    Join
                  </button>
                  <button
                    ref="cancelButtonRef"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 sm:mt-0"
                    type="button"
                    @click="handleDeclineCall"
                  >
                    Decline
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
  <!--<div>-->
  <!--  <div>You have an incoming call from {{ caller?.name }}!</div>-->
  <!--  <div class="flex justify-between w-1/2 m-auto">-->
  <!--    <button @click="$emit('answerCall', sessionName)">Join call</button>-->
  <!--    <button @click="$emit('declineCall', caller, callee, sessionName)">Decline call</button>-->
  <!--  </div>-->
  <!--</div>-->
</template>
