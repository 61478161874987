<script setup>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import { useAgora } from "@Composables/useAgora";
import { useOnlineUserStore } from "@Stores/onlineUsersStore";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ArrowsPointingOutIcon } from "@heroicons/vue/16/solid";
import { ArrowsPointingInIcon, PhoneIcon } from "@heroicons/vue/20/solid";
import { UsersIcon } from "@heroicons/vue/24/outline";
import { usePage } from "@inertiajs/vue3";
import { computed, inject, onMounted, ref, watch } from "vue";

const props = defineProps({
  sessionName: {
    type: String,
    required: false,
  },
  minimizeVideo: {
    type: Boolean,
    required: true,
  },
  instantCallContact: {
    type: Object,
    required: false,
  },
});

let [sessionUser, sessionSupplier] = props.sessionName.split(".");

const toggleMinimize = inject("toggleMinimize");

const user = computed(() => usePage().props.auth.user);
const supplier = computed(() => usePage().props.supplier);
const supplierContacts = ref(undefined);
const memberContacts = ref(undefined);
const memberContactsOnline = ref([]);
const supplierContactsOnline = ref([]);
const { onlineUsers } = useOnlineUserStore();
const session = ref(props.sessionName);
const agoraDevices = ref([]);
const agora = ref(null);
const openSettings = ref(false);
const openHelp = ref(false);
const openCallDeclined = ref(false);

if (session.value === "") {
  session.value = `${user.value.id}.${supplier.value.id}`;
}

const {
  localTracks,
  localTrackActive,
  remoteUsers,
  initAgora,
  setCameraDevice,
  setAudioDevice,
  startVideoCall,
  callUser,
  leaveCall
} = await useAgora();

onMounted(async () => {
  agora.value = await initAgora({ sessionName: session.value });
  agoraDevices.value = agora.value.devices;

  await startVideoCall();

  if (props.instantCallContact) {
    await callUser(user.value, props.instantCallContact, supplier.value);

    // Only worry about this if it's the initial person we were calling.
    window.Echo.private(`user.${user.value.id}`).listen("DeclineCall", (e) => {
      if (e.callee.id === props.instantCallContact.id) {
        openCallDeclined.value = true;
      }
    });
  }
});

window.addEventListener("beforeunload", () => {
  leaveCall();
});

const potentiallyLeaveCall = () => {
  if (Object.keys(remoteUsers).length == 0) 
    leaveCall();

  openCallDeclined.value = false;
}

axios.get(route("blue-book-platform.supplier.contacts.index", supplier.value ?? sessionSupplier)).then((response) => {
  supplierContacts.value = response.data;
  supplierContacts.value = supplierContacts.value.filter((contact) => contact.id !== user.value.id);
  supplierContacts.value.map((contact) => {
    contact.isOnline = onlineUsers.some((u) => u.id === contact.id);
    if (contact.isOnline) {
      contact.isBusy = onlineUsers.some((u) => u.id === contact.id && u.status === "busy");
    }
  });
  watch(onlineUsers, (newValue) => {
    supplierContacts.value.map((contact) => {
      contact.isOnline = newValue.some((u) => u.id === contact.id);
      if (contact.isOnline) {
        contact.isBusy = newValue.some((u) => u.id === contact.id && u.status === "busy");
      }
    });
  });
});

axios.get(route("api.members.users.index", user.value.company_id), { params: { list: "all" } }).then((response) => {
  memberContacts.value = response.data;
  memberContacts.value = memberContacts.value.filter((contact) => contact.id !== user.value.id);
  memberContacts.value.map((contact) => {
    contact.isOnline = onlineUsers.some((u) => u.id === contact.id);
    if (contact.isOnline) {
      contact.isBusy = onlineUsers.some((u) => u.id === contact.id && u.status === "busy");
    }
  });
  watch(onlineUsers, (newValue) => {
    memberContacts.value.map((contact) => {
      contact.isOnline = newValue.some((u) => u.id === contact.id);
      if (contact.isOnline) {
        contact.isBusy = newValue.some((u) => u.id === contact.id && u.status === "busy");
      }
    });
  });
});

watch(supplierContacts, (newValue) => {
  supplierContactsOnline.value = newValue.filter((contact) => {
    return contact.isOnline;
  });
});

watch(memberContacts, (newValue) => {
  memberContactsOnline.value = newValue.filter((contact) => {
    return contact.isOnline;
  });
});

// Function to get the initials of a user
const initials = (first, last) => {
  if (first && last) {
    return first.charAt(0) + last.charAt(0);
  }
  return "";
};
</script>

<style>
#local-video {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

#local-video video {
  border-radius: 0.5rem;
}

.agora_video_player {
  object-fit: contain !important;
  background-color: rgb(23 23 23);
}

#local-video-stream {
  object-fit: contain;
  border-radius: 10px;
}

.remote-video {
  width: 100%;
  max-width: none;
  max-height: none;
  aspect-ratio: 1.33;
}

[id^="remote-user-"] {
  width: 100%;
  margin-top: 10px;
}
</style>

<template>
  <div
    class="absolute z-[200] overflow-hidden"
    :class="[!minimizeVideo ? 'inset-0 bg-white h-full' : 'right-[5%] bottom-[25%] w-[250px] h-[250px]']"
  >
    <div v-show="!minimizeVideo" :class="{ 'fixed inset-y-0 z-50 flex w-72 flex-col': !minimizeVideo }">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-slate-200 bg-slate-50">
        <div v-if="!memberContacts || !supplierContacts" class="h-full">
          <div class="flex justify-center h-full items-center">
            <LoadingSpinner />
          </div>
        </div>
        <div v-else>
          <div class="flex h-16 shrink-0 items-center justify-between px-4">
            <div>
              <div class="flex items-center gap-x-3">
                <div class="flex-none rounded-full p-1 text-green-500 bg-green-500/10">
                  <div class="h-2 w-2 rounded-full bg-current"></div>
                </div>
                <h2 class="min-w-0 text-sm font-medium leading-6">
                  <span class="truncate">Live video</span>
                </h2>
              </div>
            </div>
            <div class="flex">
              <button
                class="text-sm group text-slate-500 inline-flex font-normal items-center gap-1.5 hover:text-slate-700"
                @click="toggleMinimize"
              >
                <ArrowsPointingInIcon class="size-4 text-slate-400 group-hover:text-slate-700" />
                Minimize
              </button>
            </div>
          </div>
          <nav class="flex flex-1 flex-col">
            <ul class="flex flex-1 flex-col gap-y-10" role="list">
              <li>
                <div class="font-semibold leading-6 px-4">Contacts in this call</div>
                <ul v-if="Object.keys(remoteUsers).length" class="mt-2 divide-y divide-slate-200" role="list">
                  <li class="relative flex justify-between w-full gap-x-6 px-4 py-5 cursor-not-allowed">
                    <div class="w-full">You - {{ user.company_name }}</div>
                    <div class="flex flex-row">
                      <div class="w-5">
                        <svg
                          v-if="localTrackActive.video"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          fill="none"
                          stroke="green"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          class="size-5"
                        >
                          <g id="SVGRepo_iconCarrier">
                            <rect height="7.5" width="7.5" y="4.75" x="1.75"></rect>
                            <path d="m9.75 7.25 4.5-2.5v7.5l-4.5-2.5"></path>
                          </g>
                        </svg>
                        <svg
                          v-else
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          fill="none"
                          stroke="red"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          class="size-5"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="m11.25 10.75 3 1.5v-7.5l-5 2.5v-2.5h-2.5m1.5 7.5h-6.5v-7.5h1.5"></path>
                            <line x1="1.75" y1="2.25" x2="10.25" y2="14.25"></line>
                          </g>
                        </svg>
                      </div>

                      <div class="w-5">
                        <svg
                          v-if="localTrackActive.audio"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="green"
                          class="size-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                          />
                        </svg>
                        <svg
                          v-else
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="size-5"
                        >
                          <path
                            d="M15 6C15 4.34315 13.6569 3 12 3C10.8896 3 9.92008 3.6033 9.40137 4.5M15 9L15 10M18 12C18 12.3407 17.9716 12.6748 17.9171 13M3 3L21 21M13 17.917C12.6748 17.9716 12.3407 18 12 18V21M8.68221 17C7.06551 15.9251 6 14.087 6 12M12 21H15M12 21H9"
                            stroke="red"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </li>
                  <template v-for="contact in remoteUsers" :key="contact.uid">
                    <li
                      v-if="contact.uid"
                      class="relative flex justify-between w-full gap-x-6 px-4 py-5 cursor-not-allowed"
                    >
                      <div class="w-full">{{ contact.uid }}</div>
                      <div class="flex flex-row">
                        <div class="w-5">
                          <svg
                            v-if="contact.hasVideo"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            fill="none"
                            stroke="green"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                            class="size-5"
                          >
                            <g id="SVGRepo_iconCarrier">
                              <rect height="7.5" width="7.5" y="4.75" x="1.75"></rect>
                              <path d="m9.75 7.25 4.5-2.5v7.5l-4.5-2.5"></path>
                            </g>
                          </svg>
                          <svg
                            v-else
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            fill="none"
                            stroke="red"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                            class="size-5"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path d="m11.25 10.75 3 1.5v-7.5l-5 2.5v-2.5h-2.5m1.5 7.5h-6.5v-7.5h1.5"></path>
                              <line x1="1.75" y1="2.25" x2="10.25" y2="14.25"></line>
                            </g>
                          </svg>
                        </div>

                        <div class="w-5">
                          <svg
                            v-if="contact.hasAudio"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="green"
                            class="size-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                            />
                          </svg>
                          <svg
                            v-else
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="size-5"
                          >
                            <path
                              d="M15 6C15 4.34315 13.6569 3 12 3C10.8896 3 9.92008 3.6033 9.40137 4.5M15 9L15 10M18 12C18 12.3407 17.9716 12.6748 17.9171 13M3 3L21 21M13 17.917C12.6748 17.9716 12.3407 18 12 18V21M8.68221 17C7.06551 15.9251 6 14.087 6 12M12 21H15M12 21H9"
                              stroke="red"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </li>
                  </template>
                </ul>
                <div v-else class="text-center p-6">
                  <UsersIcon class="inline-flex h-6 w-6 text-slate-500" />
                  <h3 class="mt-2 text-sm font-semibold text-slate-900">No contacts in call</h3>
                </div>
              </li>
              <li v-if="user.type === 'member'">
                <div class="font-semibold leading-6 px-4">Member contacts</div>
                <ul v-if="memberContactsOnline.length" class="mt-2 divide-y divide-slate-200" role="list">
                  <template v-for="memberContact in memberContacts" :key="memberContact">
                    <li v-if="memberContact?.isOnline">
                      <button
                        class="relative flex justify-between w-full gap-x-6 px-4 py-5 hover:bg-slate-100"
                        @click="callUser(user, memberContact, supplier)"
                      >
                        <div class="flex min-w-0 gap-x-4">
                          <div class="relative flex min-w-0 flex-1 items-center">
                            <span v-if="memberContact.avatar?.azure_file" class="relative inline-block flex-shrink-0">
                              <img
                                :src="memberContact.avatar.azure_file?.location_url"
                                alt=""
                                class="h-8 w-8 rounded-full"
                              />
                              <span
                                :class="[
                                  memberContact?.isOnline
                                    ? memberContact?.isBusy
                                      ? 'bg-primary-500'
                                      : 'bg-green-400'
                                    : 'bg-slate-400',
                                  'absolute bottom-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white',
                                ]"
                                aria-hidden="true"
                              />
                            </span>
                            <span v-else class="relative inline-block flex-shrink-0">
                              <span class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-slate-500">
                                <span class="font-medium leading-none text-white">{{
                                  initials(memberContact.first_name, memberContact.last_name)
                                }}</span>
                              </span>
                              <span
                                :class="[
                                  memberContact?.isOnline
                                    ? memberContact?.isBusy
                                      ? 'bg-primary-500'
                                      : 'bg-green-400'
                                    : 'bg-slate-400',
                                  'absolute bottom-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white',
                                ]"
                                aria-hidden="true"
                              />
                            </span>
                            <div class="ml-4 truncate text-left">
                              <p class="truncate text-sm font-medium">{{ memberContact?.name }}</p>
                              <p class="truncate text-xs text-slate-500">{{ memberContact?.company_name }}</p>
                            </div>
                          </div>
                        </div>
                      </button>
                    </li>
                  </template>
                </ul>
                <div v-else class="text-center p-6">
                  <UsersIcon class="inline-flex h-6 w-6 text-slate-500" />
                  <h3 class="mt-2 text-sm font-semibold text-slate-900">No members online.</h3>
                  <p class="mt-1 text-sm text-slate-500">Please check back later.</p>
                </div>
              </li>
              <li>
                <div class="font-semibold leading-6 px-4">Supplier contacts</div>
                <ul v-if="supplierContactsOnline.length" class="mt-2 divide-y divide-slate-200" role="list">
                  <template v-for="supplierContact in supplierContacts" :key="supplierContact.id">
                    <li v-if="supplierContact?.isOnline">
                      <button
                        :disabled="supplierContact?.isBusy"
                        class="relative flex justify-between w-full gap-x-6 px-4 py-5 hover:bg-slate-100 disabled:cursor-not-allowed"
                        @click="callUser(user, supplierContact, user.type === 'vendor' ? user.company : supplier)"
                      >
                        <div class="flex min-w-0 gap-x-4">
                          <div class="relative flex min-w-0 flex-1 items-center">
                            <span v-if="supplierContact.avatar?.azure_file" class="relative inline-block flex-shrink-0">
                              <img
                                :src="supplierContact.avatar.azure_file?.location_url"
                                alt=""
                                class="h-8 w-8 rounded-full"
                              />
                              <span
                                :class="[
                                  supplierContact?.isOnline
                                    ? supplierContact?.isBusy
                                      ? 'bg-primary-500'
                                      : 'bg-green-400'
                                    : 'bg-slate-400',
                                  'absolute bottom-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white',
                                ]"
                                aria-hidden="true"
                              />
                            </span>
                            <span v-else class="relative inline-block flex-shrink-0">
                              <span class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-slate-500">
                                <span class="font-medium leading-none text-white">{{
                                  initials(supplierContact.first_name, supplierContact.last_name)
                                }}</span>
                              </span>
                              <span
                                :class="[
                                  supplierContact?.isOnline
                                    ? supplierContact?.isBusy
                                      ? 'bg-primary-500'
                                      : 'bg-green-400'
                                    : 'bg-slate-400',
                                  'absolute bottom-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white',
                                ]"
                                aria-hidden="true"
                              />
                            </span>
                            <div class="ml-4 truncate text-left">
                              <p class="truncate text-sm font-medium">{{ supplierContact?.name }}</p>
                              <p class="truncate text-xs text-slate-500">{{ supplierContact?.company_name }}</p>
                            </div>
                          </div>
                        </div>
                      </button>
                    </li>
                  </template>
                </ul>
                <div v-else class="text-center p-6">
                  <UsersIcon class="inline-flex h-6 w-6 text-slate-500" />
                  <h3 class="mt-2 text-sm font-semibold text-slate-900">No suppliers online.</h3>
                  <p class="mt-1 text-sm text-slate-500">Please check back later.</p>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <main class="lg:pl-72 h-full w-full overflow-hidden">
      <div class="h-full w-full" :class="{ 'absolute left-0 top-0': minimizeVideo }">
        <div class="mx-auto h-full w-full flex flex-col">
          <div v-if="minimizeVideo" class="w-full">
            <div class="bg-slate-950 px-3 py-2 rounded-lg text-white border-2 border-yellow-400">
              <div class="flex items-center justify-between">
                <div class="flex">
                  <button
                    class="group text-sm text-slate-200 inline-flex font-normal items-center gap-1.5 hover:text-white"
                    @click="toggleMinimize"
                  >
                    <ArrowsPointingOutIcon class="size-4 text-slate-300 group-hover:text-slate-100" />
                    Maximize
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full h-full flex flex-col">
            <div class="h-full w-full flex flex-row">
              <div
                v-show="Object.keys(remoteUsers).length > 2 && !minimizeVideo"
                id="agora-remote-video-container"
                class="h-full w-[20%] flex flex-col justify-center align-center px-2 flex-nowrap bg-neutral-900 overflow-x-hidden overflow-y-auto"
              ></div>
              <div id="" class="h-full w-full bg-neutral-900 flex flex-col">
                <div id="agora-full-screen-video-container" class="h-full w-full bg-black"></div>
                <div
                  v-show="!minimizeVideo"
                  id="agora-media-controls-container"
                  class="flex h-[100px] w-full justify-center bottom-10 px-10 z-40 bg-neutral-900"
                >
                  <button
                    id="agora-mic-btn"
                    class="m-5 flex flex-col items-center rounded-full border border-transparent bg-primary-600 px-4 py-2 text-xs text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    type="button"
                  >
                    <svg
                      v-if="localTrackActive.audio"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-7"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                      />
                    </svg>
                    <svg v-else viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15 6C15 4.34315 13.6569 3 12 3C10.8896 3 9.92008 3.6033 9.40137 4.5M15 9L15 10M18 12C18 12.3407 17.9716 12.6748 17.9171 13M3 3L21 21M13 17.917C12.6748 17.9716 12.3407 18 12 18V21M8.68221 17C7.06551 15.9251 6 14.087 6 12M12 21H15M12 21H9"
                        stroke="#000000"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <span v-if="localTrackActive.audio">Mute</span>
                    <span v-else>Unmute</span>
                  </button>

                  <button
                    id="agora-video-btn"
                    class="m-5 flex flex-col items-center rounded-full border border-transparent bg-primary-600 px-4 py-2 text-xs text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    type="button"
                  >
                    <svg
                      v-if="localTrackActive.video"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <rect height="7.5" width="7.5" y="4.75" x="1.75"></rect>
                        <path d="m9.75 7.25 4.5-2.5v7.5l-4.5-2.5"></path>
                      </g>
                    </svg>
                    <svg
                      v-else
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      fill="none"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="m11.25 10.75 3 1.5v-7.5l-5 2.5v-2.5h-2.5m1.5 7.5h-6.5v-7.5h1.5"></path>
                        <line x1="1.75" y1="2.25" x2="10.25" y2="14.25"></line>
                      </g>
                    </svg>

                    <span v-if="localTrackActive.video">Stop Camera</span>
                    <span v-else>Start Camera</span>
                  </button>

                  <button
                    id="agora-share-screen-btn"
                    class="m-5 flex flex-col items-center rounded-full border border-transparent bg-primary-600 px-4 py-2 text-xs text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    type="button"
                  >
                    <svg
                      v-if="localTrackActive.screen"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M17 17H4a2 2 0 01-2-2V5c0-1.5 1-2 1-2"></path>
                        <path d="M22 15V5a2 2 0 00-2-2H9"></path>
                        <path d="M8 21h8"></path>
                        <path d="M12 17v4"></path>
                        <path d="M2 2l20 20"></path>
                      </g>
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                        <line x1="8" y1="21" x2="16" y2="21"></line>
                        <line x1="12" y1="17" x2="12" y2="21"></line>
                      </g>
                    </svg>

                    <span v-if="localTrackActive.screen">Stop Sharing</span>
                    <span v-else>Share Screen</span>
                  </button>

                  <button
                    id="agora-leave-channel-btn"
                    class="m-5 flex flex-col items-center rounded-full border border-transparent bg-primary-600 px-4 py-2 text-xs text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-7"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 3.75 18 6m0 0 2.25 2.25M18 6l2.25-2.25M18 6l-2.25 2.25m1.5 13.5c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
                      />
                    </svg>
                    End Call
                  </button>

                  <button
                    id="agora-settings-btn"
                    class="m-5 flex flex-col items-center rounded-full border border-transparent bg-primary-600 px-4 py-2 text-xs text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    type="button"
                    @click="openSettings = true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-7"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                      />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    Settings
                  </button>

                  <button
                    id="agora-help-btn"
                    class="m-5 flex flex-col items-center rounded-full border border-transparent bg-primary-600 px-4 py-2 text-xs text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    type="button"
                    @click="openHelp = true"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M12 19H12.01M8.21704 7.69689C8.75753 6.12753 10.2471 5 12 5C14.2091 5 16 6.79086 16 9C16 10.6565 14.9931 12.0778 13.558 12.6852C12.8172 12.9988 12.4468 13.1556 12.3172 13.2767C12.1629 13.4209 12.1336 13.4651 12.061 13.6634C12 13.8299 12 14.0866 12 14.6L12 16"
                      ></path>
                    </svg>
                    Help
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-show="!minimizeVideo"
        id="agora-local-video-container"
        class="absolute float-right bottom-[85px] right-[25px] w-[250px] h-[200px]"
      >
        <div id="local-video" class="absolute z-50"></div>
      </div>
    </main>
  </div>

  <TransitionRoot :show="openSettings" as="template">
    <Dialog as="div" class="relative z-[300]" @close="() => {}">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div aria-hidden="true" class="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-h-body dialog-panel"
            >
              <div>
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="grid grid-cols-2 gap-10">
                    <div>
                      Select your preferred camera:
                      <select
                        @change="setCameraDevice"
                        class="block w-full py-2 pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                      >
                        <option
                          v-for="device in agoraDevices.videoDevices"
                          :key="device.deviceId"
                          :value="device.deviceId"
                          :selected="localTracks.camera.video?.getTrackLabel() == device.label"
                        >
                          {{ device.label }}
                        </option>
                      </select>
                    </div>

                    <div>
                      Selected your preferred microphone:
                      <select
                        @change="setAudioDevice"
                        class="block w-full py-2 pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                      >
                        <option
                          v-for="device in agoraDevices.audioDevices"
                          :key="device.deviceId"
                          :value="device.deviceId"
                          :selected="localTracks.camera.audio?.getTrackLabel() == device.label"
                        >
                          {{ device.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="bg-slate-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    ref="cancelButtonRef"
                    class="mt-3 rounded-md bg-white py-2.5 px-3.5 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    type="button"
                    @click="openSettings = false"
                  >
                    Close
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot :show="openHelp" as="template">
    <Dialog as="div" class="relative z-[300]" @close="() => {}">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div aria-hidden="true" class="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-h-body dialog-panel"
            >
              <div>
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="text-2xl">
                    <strong
                      >Below you will find a list of explanations of each component of the video chat as well as some
                      answers to common questions:</strong
                    >
                  </div>

                  <div class="mt-10 text-2xl">
                    <strong>Your typical video chat will looks something similar to this:</strong>
                    <img src="/img/events/video-help/videomain.png" />
                  </div>

                  <div class="mt-10 text-2xl">
                    <strong><u>Lets break down each section/component:</u></strong>
                  </div>

                  <div class="flex flex-row mt-10">
                    <div class="w-[20%]"><img src="/img/events/video-help/videoleft.png" /></div>
                    <div class="w-full pl-10">
                      <ul>
                        <li>
                          <strong>Far left white box:</strong> This will list all the contacts in your booth your able
                          to invite to your current call. <strong>*SUPPLIERS*</strong> keep in mind you will not be able
                          to invite members to a call only others from your same company that are online. You simply
                          just click on a contact and it will ask them to join the call.
                        </li>
                        <li class="pt-10">
                          <strong>Participant videos:</strong> If you have more than 1 other person in the call you will
                          see their videos pop up to the left of the main video. The main video will automatically
                          change to who is speaking. If you'd like to stop it from doing that you can double click on
                          the person you'd like to make the primary.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="flex flex-col mt-10">
                    <div class="w-full"><img src="/img/events/video-help/videobottom.png" /></div>
                    <div class="w-full pl-10">
                      <ul>
                        <li><strong>Mute button:</strong> This will toggle on and off your microphone.</li>
                        <li><strong>Stop Camera button:</strong> This will toggle on and off your camera video.</li>
                        <li><strong>End Call button:</strong> This will hang up the call.</li>
                        <li>
                          <strong>Settings button:</strong> This will open up a settings box that lets you choose your
                          microphone and webcam if you have multiple.
                        </li>
                        <li>
                          <strong>Video on bottom right:</strong> If you have a camera turned on you will see your video
                          in this portion on the bottom right of the screen.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="mt-10 text-2xl">
                    <strong><u>Commonly asked questions:</u></strong>
                  </div>

                  <ul class="list-disc">
                    <li class="pt-5">
                      <div><strong>My audio/video isn't working:</strong></div>
                      <div class="pl-10">
                        <ul class="list-decimal">
                          <li>
                            <div>
                              Make sure you have given the browser permission to use your camera and microphone. When
                              you refresh your page you should see a popup like the below. One for the camera and one
                              for the microphone.
                            </div>
                            <div class="flex flex-row gap-5">
                              <img src="/img/events/video-help/allowcamera.png" /><img
                                src="/img/events/video-help/allowmicrophone.png"
                              />
                            </div>
                          </li>
                          <li class="pt-5">
                            <div>
                              If your not seeing that popup you may have already had your devices blocked. You should
                              see something like the below image in your URL toolbar. When you click it, you should see
                              a popup and can allow your devices at that point.
                            </div>
                            <div><img src="/img/events/video-help/devicesblocked.png" /></div>
                          </li>
                          <li class="pt-5">
                            If your still not working, click the settings button and make sure you have the correct
                            camera and microphone selected.
                          </li>
                          <li class="pt-5">
                            If your still not working make sure your camera and microphone are plugged in and turned on.
                            At this point you may need to reach out to your IT department.
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="pt-5">
                      <div>
                        <strong><u>Why cant I call members?</u></strong>
                      </div>
                      <div>
                        Only members are able to initiate calls on the platform. As a supplier you can invite others
                        from your booth once your in a call.
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="bg-slate-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    ref="cancelButtonRef"
                    class="mt-3 rounded-md bg-white py-2.5 px-3.5 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    type="button"
                    @click="openHelp = false"
                  >
                    Close
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot :show="openCallDeclined" as="template">
    <Dialog as="div" class="relative z-[300]" @close="() => {}">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-slate-100">
                  <PhoneIcon aria-hidden="true" class="h-6 w-6 text-slate-600" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6">Declined call </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-slate-500">
                      <span class="font-semibold">{{ instantCallContact?.name }} has declined your call.</span>.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense">
                <button
                  ref="cancelButtonRef"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 sm:mt-0"
                  type="button"
                  @click="potentiallyLeaveCall"
                >
                  Ok
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
