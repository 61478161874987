<template>
  <div>
    <div v-if="item.groups" class="space-y-2">
      <div v-for="(group, groupIndex) in item.groups" :key="groupIndex">
        <div class="text-xs font-semibold leading-6 text-slate-600 pl-14 uppercase">{{ group.name }}</div>
        <p-side-bar-navigation-item-link-item
          v-for="(child, childIndex) in group.children"
          :key="childIndex"
          :indent="indent"
          :item="child"
        />
      </div>
    </div>
    <div v-else>
      <p-side-bar-navigation-item-link-item :indent="indent" :item="item" />
    </div>
  </div>
</template>

<script setup>
import PSideBarNavigationItemLinkItem from "./PSideBarNavigationItemLinkItem.vue";
import { toRefs } from "vue";

const props = defineProps({
  item: Object,
  indent: { type: Boolean, default: false },
});

const { item, indent } = toRefs(props);
</script>
