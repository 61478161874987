<template>
  <p-side-bar-navigation-item-link v-if="!item.children" :item="item" />
  <Disclosure v-else v-slot="{ open }" :default-open="item.defaultOpen" as="div">
    <DisclosureButton
      class="text-slate-400 hover:bg-slate-800 hover:text-white group w-full flex items-center px-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none"
    >
      <component
        :is="item.icon"
        aria-hidden="true"
        class="mr-3 flex-shrink-0 h-5 w-5 text-slate-500 group-hover:text-slate-400"
      />
      <span class="flex flex-col">
        {{ item.name }}
        <small v-if="item?.subName" class="" v-text="item.subName"></small>
      </span>
      <ChevronRightIcon
        :class="[
          open ? 'rotate-90' : '',
          'text-slate-600 ml-3 flex-shrink-0 h-5 w-5 transform transition-all duration-200',
        ]"
      />
    </DisclosureButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-200 opacity-200"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-200 opacity-200"
      leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel class="py-1">
        <p-side-bar-navigation-item-link v-for="item in item.children" :key="item.name" :item="item" indent />
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { toRefs } from "vue";
import PSideBarNavigationItemLink from "./PSideBarNavigationItemLink.vue";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
  item: { type: Object, required: true },
});

const { item } = toRefs(props);
</script>
