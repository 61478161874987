<template>
  <Suspense>
    <div>
      <AgoraIncomingCall
        v-show="showCallWaiting"
        @answerCall="answerCall"
        @canceledSession="canceledSession"
        @declineCall="declineCall"
        @incomingCall="incomingCall"
        @leftCall="leftCall"
      />
      <AgoraVideoContainer v-if="openAgoraVideo" :instantCallContact :minimizeVideo :sessionName="sessionName" />
    </div>
  </Suspense>
</template>

<script setup>
import { useOnlineUserStore } from "@Stores/onlineUsersStore";
import { inject, ref } from "vue";
import AgoraIncomingCall from "./AgoraIncomingCall.vue";
import AgoraVideoContainer from "./AgoraVideoContainer.vue";

const props = defineProps({
  openAgoraVideo: {
    type: Boolean,
    required: true,
    default: false,
  },
  minimizeVideo: {
    type: Boolean,
    required: true,
    default: false,
  },
  instantCallContact: {
    type: Object,
    required: false,
    default: null,
  },
});

const { makeUserAvailable } = useOnlineUserStore();

const sessionName = ref("");
const incomingCallAudio = new Audio("/incoming_call.mp3");
const showCallWaiting = ref(false);

incomingCallAudio.loop = true;

const toggleAgoraVideo = inject("toggleAgoraVideo");

window.addEventListener("beforeunload", () => {
  sessionName.value = "";
});

const resetAudio = async () => {
  incomingCallAudio.pause();
  incomingCallAudio.currentTime = 0;
};

const resetCall = async () => {
  showCallWaiting.value = false;
  await resetAudio();
  sessionName.value = "";
};

const incomingCall = async (session) => {
  if (sessionName.value !== "") {
    return;
  }

  showCallWaiting.value = true;
  await resetAudio();
  incomingCallAudio.play();
  sessionName.value = session.sessionName;
};

const answerCall = (caller, callee, session) => {
  resetCall();
  sessionName.value = session;
  toggleAgoraVideo();
  // Join the video channel and whisper callAnswered, because it was
  Echo.private(`video.${sessionName.value}`).whisper("callAnswered", {
    sessionName: session,
  });
};

const declineCall = (caller, callee, sessionName) => {
  try {
    axios.post(route("api.decline-call.index"), {
      caller: caller.id,
      callee: callee.id,
      sessionName: sessionName,
    });

    makeUserAvailable(callee.id);
  } catch (error) {
    // console.log("Decline Call Failed", error);
  }
  resetCall();
};

const leftCall = (user) => {
  resetCall();
  if (user?.id) {
    makeUserAvailable(user.id);
  }
};

const canceledSession = (caller, callee, sessionName) => {
  declineCall(caller, callee, sessionName);
  makeUserAvailable(callee.id);
  toggleAgoraVideo();
};
</script>
