<template>
  <div
    class="min-h-full flex flex-col justify-center bg-[url('https://images.unsplash.com/photo-1554973653-c9071bd14011?ixlib=rb-1.2.1&auto=format&w=2560&h=1234&q=60')] bg-center bg-cover"
  >
    <div class="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-slate-900/60 to-slate-700/60 z-0"></div>
    <div class="min-h-screen flex flex-col justify-center items-center z-10">
      <div class="px-4 sm:px-0">
        <Link href="/">
          <ApplicationLogo />
        </Link>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-white">Sign in to your account</h2>
        </div>
      </div>

      <div class="w-full sm:max-w-md mt-6 py-8 px-4 sm:px-10 bg-white shadow-md overflow-hidden sm:rounded-lg">
        <div v-if="message" class="rounded-md bg-yellow-50 p-4 mb-6">
          <div class="flex">
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-sm text-yellow-700" v-text="message"></p>
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="text-white mt-2 text-sm">
        Need an account?
        <a href="https://msdist.com/contact" class="font-medium text-primary-200 hover:text-primary-400"
          >Contact Mid-States</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { Link, usePage } from "@inertiajs/vue3";
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";

const message = computed(() => usePage().props.flash?.message);
</script>
