<script setup>
import ITSupportBanner from "@/Components/ITSupportBanner.vue";
import { PPage } from "@/Library";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { Head, Link, usePage } from "@inertiajs/vue3";
import pluralize from "pluralize";
import { computed } from "vue";

const event = computed(() => usePage().props.event);

const navigation = [
  {
    name: "Getting Started",
    route: "events.event.registration.step-two.get-started.index",
    component: "Events/Event/Registration/Steps/Two/GetStarted",
  },
  {
    name: "Company Details",
    route: "events.event.registration.step-two.company-details.index",
    component: "Events/Event/Registration/Steps/Two/CompanyDetails",
  },
  ...(event.value?.has_show_only_buys
    ? [
        {
          name: `Enter ${pluralize(event.value.event_type.show_only_buy_label)}`,
          route: "events.event.registration.step-two.show-only-buys.index",
          component: "Events/Event/Registration/Steps/Two/ShowOnlyBuys",
        },
      ]
    : []),
    ...(event.value?.has_show_specials
    ? [
        {
          name: "Enter 30/30 Specials",
          route: "events.event.registration.step-two.event-specials.index",
          component: "Events/Event/Registration/Steps/Two/3030Specials",
        },
      ]
    : []),
  ...(event.value?.has_new_items && event.value?.event_type.name !== 'Toy Show'
    ? [
        {
          name: `Enter ${pluralize(event.value.event_type.new_item_label)}`,
          route: "events.event.registration.step-two.new-items.index",
          component: "Events/Event/Registration/Steps/Two/NewItems",
        },
      ]
    : []),
  ...(event.value?.in_person === false
    ? [
        {
          name: "Door Prize Giveaway",
          route: "events.event.registration.step-two.giveaway.index",
          component: "Events/Event/Registration/Steps/Two/Giveaway",
        },
      ]
    : []),
  {
    name: "Event/Show Attendees",
    route: "events.event.registration.step-two.booth-contacts.index",
    component: "Events/Event/Registration/Steps/Two/BoothContacts",
  },
  {
    name: "Booth Design",
    route: "events.event.registration.step-two.booth-design.index",
    component: "Events/Event/Registration/Steps/Two/BoothDesign",
  },
  {
    name: "Upload Videos",
    route: "events.event.registration.step-two.upload-videos.index",
    component: "Events/Event/Registration/Steps/Two/UploadVideos",
  },
  {
    name: "Upload Documents",
    route: "events.event.registration.step-two.upload-documents.index",
    component: "Events/Event/Registration/Steps/Two/UploadDocuments",
  },
  ...(event.value?.has_new_items && event.value?.event_type.name == 'Toy Show'
    ? [
        {
          name: `Enter ${pluralize(event.value.event_type.new_item_label)}`,
          route: "events.event.registration.step-two.new-items.index",
          component: "Events/Event/Registration/Steps/Two/NewItems",
        },
      ]
    : []),
  ...(event.value?.event_type.name == 'Toy Show'
    ? [
        {
          name: "Toy Catalog Page Designer",
          route: "events.event.registration.step-two.catalog-page-designs.index",
          component: "Events/Event/Registration/Steps/Two/CatalogPageDesigner",
        },
      ]
    : []),
  {
    name: "Sponsorship Assets",
    route: "events.event.registration.step-two.sponsorship-ads.index",
    component: "Events/Event/Registration/Steps/Two/SponsorshipAds",
  },
];
</script>

<template>
  <Head title="Registration" />
  <ITSupportBanner />
  <PPage header-class="py-3 scroll-mt-8">
    <!-- <template #header>
      <div class="xl:max-w-7xl xl:mx-auto">
        <h2 class="flex items-center space-x-3 mb-6 text-xl font-semibold leading-7">
          <Link
            :href="
              route('events.event.registration.index', { event: $page.props.event.id, vendor: $page.props.vendor.id })
            "
            class="rounded-full bg-primary-500 p-1 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
          >
            <ArrowLeftIcon aria-hidden="true" class="h-4 w-4" />
          </Link>
          <span> Registration steps</span>
        </h2>
        <div class="md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1">
            <div class="flex items-center h-9 space-x-5">
              <h3 class="flex items-center space-x-3 text-2xl font-bold leading-7 sm:text-3xl sm:truncate capitalize">
                Step 2
              </h3>
              <span class="bg-slate-500/30 w-px h-full"></span>
              <p class="text-xl sm:text-2xl text-slate-600 font-medium">Show Specials Setup</p>
            </div>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4"></div>
        </div>
      </div>
    </template> -->
    <div>
      <div class="flex">
        <div class="flex w-64 flex-col shrink-0">
          <div class="flex grow flex-col gap-y-5">
            <nav class="flex flex-1 flex-col">
              <ul class="flex flex-1 flex-col gap-y-7" role="list">
                <li>
                  <ul class="space-y-1" role="list">
                    <li>
                      <div class="mb-2 text-sm leading-7 flex">
                        <Link
                          :href="route('events.event.registration.index', [$page.props.event, $page.props.vendor])"
                          class="flex items-center space-x-2 group"
                        >
                          <span
                            class="rounded-full bg-slate-500 p-1 text-white shadow-sm group-hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                          >
                            <ArrowLeftIcon aria-hidden="true" class="h-3 w-3" />
                          </span>
                          <span class="text-slate-500 group-hover:text-slate-700">Back to registration</span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div class="flex items-center h-9 space-x-2 mb-5">
                        <h3 class="flex items-center space-x-3 font-semibold leading-7 sm:truncate capitalize">
                          Step 2
                        </h3>
                        <span>-</span>
                        <p class="text-slate-600 font-medium">Trade Show Setup</p>
                      </div>
                    </li>
                    <li v-for="(item, index) in navigation" :key="item.name">
                      <Link
                        v-if="!item.children"
                        :class="[
                          $page.component.startsWith(item.component)
                            ? 'bg-slate-200/60 text-slate-900'
                            : 'text-slate-500 hover:bg-slate-200/60',
                          'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium',
                        ]"
                        :href="route(item.route, { event: $page.props.event.id, vendor: $page.props.vendor.id })"
                      >
                        <div class="flex items-center gap-3">
                          <span>{{ index + 1 }}.</span>
                          <span>{{ item.name }}</span>
                        </div>
                      </Link>
                      <Disclosure v-else-if="item.children" v-slot="{ open }" as="div">
                        <DisclosureButton
                          class="text-slate-500 hover:bg-slate-200/60 hover:text-slate-500 group w-full flex items-center px-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none justify-between"
                        >
                          <span class="flex items-center gap-3">
                            <span>{{ index + 1 }}.</span>
                            <span>{{ item.name }}</span>
                          </span>
                          <ChevronRightIcon
                            :class="[
                              open ? 'rotate-90' : '',
                              'text-slate-500 ml-3 flex-shrink-0 h-5 w-5 transform transition-all duration-200',
                            ]"
                          />
                        </DisclosureButton>
                        <transition
                          enter-active-class="transition duration-200 ease-out"
                          enter-from-class="transform scale-95 opacity-0"
                          enter-to-class="transform scale-200 opacity-200"
                          leave-active-class="transition duration-75 ease-out"
                          leave-from-class="transform scale-200 opacity-200"
                          leave-to-class="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel class="py-1 space-y-1">
                            <Link
                              v-for="item in item.children"
                              :key="item.name"
                              :class="[
                                $page.component.startsWith(item.component)
                                  ? 'bg-slate-200/60 text-slate-900'
                                  : 'text-slate-500 hover:bg-slate-200/60',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium pl-14',
                              ]"
                              :href="route(item.route, { event: $page.props.event.id, vendor: $page.props.vendor.id })"
                            >
                              {{ item.name }}
                            </Link>
                          </DisclosurePanel>
                        </transition>
                      </Disclosure>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <main class="flex-grow">
          <div class="px-4 sm:px-6 lg:px-8">
            <slot />
          </div>
        </main>
      </div>
    </div>
  </PPage>
</template>
