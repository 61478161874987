<template>
  <header v-bind="$attrs">
    <TransitionRoot
      appear
      :show="isShowing"
      enter="transition duration-300 delay-100 ease-in"
      enter-from="opacity-0 -translate-y-full"
      enter-to="opacity-100 translate-y-0"
      leave="transition duration-1000 ease-in"
      leave-from="opacity-100 translate-y-0"
      leave-to="opacity-0 -translate-y-full"
    >
      <div class="max-w-full mx-auto px-4 sm:px-6 md:px-8">
        <slot></slot>
      </div>
    </TransitionRoot>
  </header>
</template>

<script setup>
import { ref } from "vue";
import { TransitionRoot } from "@headlessui/vue";

const isShowing = ref(true);
</script>
