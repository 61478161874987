<script setup>
import { useEventsStore } from "@/Stores/events";
import { useDateFormatter } from "@Composables";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ChevronRightIcon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { Link, router, usePage } from "@inertiajs/vue3";
import pluralize from "pluralize";
import { computed, onMounted, ref } from "vue";

const { formatDate } = useDateFormatter();

const page = usePage();

const event = computed(() => page.props.event);
const user = computed(() => page.props.auth.user);

onMounted(() => {
  if (user.value.type === "admin") {
    const eventsStore = useEventsStore();
    eventsStore.fetchEvents();
  }
});

const isActive = (item) => {
  return false;
};

const store = useEventsStore();
const navigation = ref([
  // { name: "Dashboard", route: "events.event.dashboard.index", component: "Events/Event/Dashboard" },
  { name: "Settings", route: "events.event.settings.edit", component: "Events/Event/Settings" },
  {
    name: "Financial Approval",
    route: "events.event.financial-approval.index",
    component: "Events/Event/FinancialApproval",
  },
  // { name: "Registration", route: "events.event.registration.index", component: "Events/Event/Registration" },
  { name: "Show Floor", route: "events.event.show.show-floor.index", component: "Events/Event/Show/ShowFloor/Index" },
  { name: "Suppliers", route: "events.event.suppliers.index", component: "Events/Event/Suppliers" },
  { name: "Giveaways", route: "events.event.giveaways.index", component: "Events/Event/Giveaways" },
  { name: "RSVPs", route: "events.event.rsvps.index", component: "Events/Event/Rsvps" },
  { name: "Booths", route: "events.event.booths.index", component: "Events/Event/Booths" },
  { name: "Sponsorships", route: "events.event.sponsorships.index", component: "Events/Event/Sponsorships" },

  {
    name: "Products",
    route: "events.event.lobby.index",
    component: "Events/Event/Show/ShowSpecials/Index",
    children: [
      ...(event.value?.has_show_only_buys
        ? [
            {
              name: pluralize(event.value.event_type.show_only_buy_label),
              route: "events.event.show-only-buys.index",
              component: "Events/Event/ShowOnlyBuys",
            },
          ]
        : []),
      ...(event.value?.has_show_specials
        ? [
            {
              name: "Show Specials",
              route: "events.event.specials.index",
              component: "Events/Event/Specials",
            },
          ]
        : []),
      ...(event.value?.has_show_specials || event.value?.has_show_only_buys
        ? [
            {
              name: "Show Specials Approval",
              route: "events.event.show-specials-approval.index",
              component: "Events/Event/ShowSpecialsApproval",
            },
          ]
        : []),
      ...(event.value?.has_new_items
        ? [
            {
              name: pluralize(event.value.event_type.new_item_label),
              route: "events.event.new-items.index",
              component: "Events/Event/NewItems",
            },
          ]
        : []),
      ...(event.value?.has_paddle_buys
        ? [
            {
              name: "Paddle Buys",
              route: "events.event.paddle-buys.index",
              component: "Events/Event/PaddleBuys",
            },
          ]
        : []),
    ],
  },

  { name: "Reminders", route: "events.event.reminders.index", component: "Events/Event/Reminders" },
  { name: "Commitments", route: "events.event.commitments.index", component: "Events/Event/Commitments" },

  {
    name: "Reports",
    route: "events.event.lobby.index",
    component: "Events/Event/Show/ShowSpecials/Index",
    children: [
      {
        name: "Contact Report",
        route: "events.event.reports.contact.index",
        component: "Events/Event/Reports/Contact/Index",
      },
      {
        name: "Supplier Report",
        route: "events.event.reports.supplier.index",
        component: "Events/Event/Reports/Supplier/Index",
      },
      {
        name: "Booth Overview Report",
        route: "events.event.reports.booth-overview.index",
        component: "Events/Event/Reports/BoothOverview/Index",
      },
      {
        name: "Booth Not Setup Report",
        route: "events.event.reports.booth-not-setup.index",
        component: "Events/Event/Reports/BoothNotSetup/Index",
      },
      {
        name: "Booth Reps Missing Report",
        route: "events.event.reports.booth-reps-missing.index",
        component: "Events/Event/Reports/BoothRepsMissing/Index",
      },
      {
        name: "Sponsorships Report",
        route: "events.event.reports.sponsorships.index",
        component: "Events/Event/Reports/Sponsorships/Index",
      },
      {
        name: "RSVP Attendance Report",
        route: "events.event.reports.rsvp-attendance.index",
        component: "Events/Event/Reports/RsvpAttendance/Index",
      },
      {
        name: "Badge Requests Report",
        route: "events.event.reports.badge-request.index",
        component: "Events/Event/Reports/BadgeRequest/Index",
      },
    ],
  },
]);

const open = ref(false);
const query = ref("");

const filteredEvent = computed(() =>
  query.value === ""
    ? store.events
    : store.events.filter((event) => {
        return event.name.toLowerCase().includes(query.value.toLowerCase());
      })
);

function onSelect(event) {
  router.get(route("events.event.dashboard.index", { event: event.id }));
  open.value = false;
}
</script>

<template>
  <div class="flex h-full">
    <aside class="bg-slate-900/95 border-l border-slate-700/30 xl:fixed xl:flex xl:flex-col h-full hidden w-64">
      <div class="flex-1 flex flex-col overflow-y-auto">
        <nav class="flex flex-1 flex-col gap-y-5 px-4 py-6 sm:px-6">
          <button
            class="inline-flex items-center gap-x-1.5 rounded-md bg-slate-700/70 -mx-2 px-3 py-2 text-sm text-slate-400 focus:outline-none hover:bg-slate-700 ring-1 ring-slate-900/10"
            style="box-shadow: inset 0 1px 0 0 #ffffff0d"
            type="button"
            @click="open = true"
          >
            <MagnifyingGlassIcon aria-hidden="true" class="-ml-0.5 h-5 w-5" />
            Quick search...
          </button>
          <ul class="flex flex-1 flex-col gap-y-7" role="list">
            <li>
              <ul class="-mx-2 space-y-1" role="list">
                <li v-for="(item, index) in navigation" :key="item.name">
                  <a
                    v-if="item.url && item.download"
                    :href="item.url"
                    class="group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium text-slate-400 hover:text-white hover:bg-slate-700/50"
                    target="_blank"
                    v-text="item.name"
                  ></a>
                  <Link
                    v-else-if="item.route && !item?.children"
                    :class="[
                      $page.component.startsWith(item.component)
                        ? 'bg-slate-900 text-white'
                        : 'text-slate-400 hover:text-white hover:bg-slate-700/50',
                      'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium',
                    ]"
                    :href="route(item.route, { event: $page.props.event.id })"
                  >
                    {{ item.name }}
                  </Link>
                  <Disclosure v-else-if="item.children" v-slot="{ open }" :defaultOpen="false" as="div">
                    <DisclosureButton
                      class="text-slate-400 hover:bg-slate-700/50 hover:text-white group w-full flex items-center px-3 py-2 text-left text-sm font-medium rounded-md focus:outline-none justify-between"
                    >
                      <span class="flex items-center gap-3">
                        <span>{{ item.name }}</span>
                      </span>
                      <ChevronRightIcon
                        :class="[
                          open ? 'rotate-90' : '',
                          'text-slate-600 ml-3 flex-shrink-0 h-5 w-5 transform transition-all duration-200',
                        ]"
                      />
                    </DisclosureButton>
                    <transition
                      enter-active-class="transition duration-200 ease-out"
                      enter-from-class="transform scale-95 opacity-0"
                      enter-to-class="transform scale-200 opacity-200"
                      leave-active-class="transition duration-75 ease-out"
                      leave-from-class="transform scale-200 opacity-200"
                      leave-to-class="transform scale-95 opacity-0"
                    >
                      <DisclosurePanel class="py-1">
                        <template v-for="item in item.children" :key="item.name">
                          <a
                            v-if="item.url && item.download"
                            :href="item.url"
                            class="group flex gap-x-3 rounded-md px-3 py-2 pl-6 text-sm font-medium text-slate-400 hover:text-white hover:bg-slate-700/50"
                            target="_blank"
                            v-text="item.name"
                          ></a>
                          <Link
                            v-else
                            :class="[
                              $page.component.startsWith(item.component)
                                ? 'bg-slate-900 text-white'
                                : 'text-slate-400 hover:bg-slate-700/50 hover:text-white',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-medium pl-6',
                            ]"
                            :href="route(item.route, { event: $page.props.event.id })"
                          >
                            {{ item.name }}
                          </Link>
                        </template>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                  <button
                    v-else
                    :class="[
                      $page.component.startsWith(item.component)
                        ? 'bg-slate-900 text-white'
                        : 'text-slate-400 hover:text-white hover:bg-slate-700/50',
                      'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium w-full',
                    ]"
                    @click="item.action"
                  >
                    {{ item.name }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
    <div class="flex-1 h-full overflow-auto xl:pl-64">
      <slot></slot>
    </div>
    <TransitionRoot :show="open" appear as="template" @after-leave="query = ''">
      <Dialog as="div" class="relative z-20" @close="open = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="mx-auto max-w-xl transform divide-y divide-slate-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
            >
              <Combobox @update:modelValue="onSelect">
                <div class="relative">
                  <MagnifyingGlassIcon
                    aria-hidden="true"
                    class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-slate-400"
                  />
                  <ComboboxInput
                    class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-slate-900 placeholder:text-slate-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    @change="query = $event.target.value"
                  />
                </div>
                <ComboboxOptions
                  v-if="filteredEvent.length > 0"
                  v-show="open"
                  class="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3"
                  static
                >
                  <ComboboxOption
                    v-for="event in filteredEvent"
                    :key="event.id"
                    v-slot="{ active }"
                    :value="event"
                    as="template"
                  >
                    <li :class="['flex cursor-pointer select-none rounded-xl p-3', active && 'bg-slate-100']">
                      <div class="flex flex-none items-center justify-center rounded-lg w-28 flex-shrink-0">
                        <img :src="event.image_url" alt="" class="w-28 h-16 rounded object-cover" />
                      </div>
                      <div class="ml-4 flex-auto">
                        <p :class="['font-medium', active ? 'text-slate-900' : 'text-slate-700']">
                          {{ event.name }}
                        </p>
                        <p :class="['text-sm', active ? 'text-slate-700' : 'text-slate-500']">
                          {{ event.city }}, {{ event.state }}
                        </p>
                        <p :class="['text-sm', active ? 'text-slate-700' : 'text-slate-500']">
                          {{ formatDate(event.starts_at) }} - {{ formatDate(event.ends_at) }}
                        </p>
                      </div>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>

                <p v-if="query !== '' && filteredEvent.length === 0" class="p-4 text-sm text-slate-500">
                  No events found.
                </p>
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
