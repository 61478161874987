import { reactive, ref } from "vue";

let show = ref(false);

const dismiss = () => {
  confirmation.show = false;
};

const handleAction = (callback = null) => {
  dismiss();
  if (callback) callback();
};

const confirmation = reactive({
  show: false,
  element: "body",
  title: "Confirmation Title",
  message: "Confirmation Message",
  okLabel: "Confirm",
  cancelLabel: "Cancel",
  showCancelButton: true,
  handleOk: handleAction,
  handleCancel: handleAction,
});

export default function useConfirmation() {
  const confirm = ({
    title,
    message,
    okLabel,
    cancelLabel,
    onOk = null,
    onCancel = null,
    element = null,
    showCancelButton = true,
  }) => {
    if (confirmation.show) {
      confirmation.show = false;
    }
    confirmation.title = title || confirmation.title;
    confirmation.message = message || confirmation.message;
    confirmation.okLabel = okLabel || confirmation.okLabel;
    confirmation.cancelLabel = cancelLabel || confirmation.cancelLabel;
    confirmation.showCancelButton = showCancelButton;
    confirmation.handleOk = () => handleAction(onOk);
    confirmation.handleCancel = () => handleAction(onCancel);
    confirmation.show = true;
    confirmation.element = element || confirmation.element;
  };

  return {
    dismiss,
    confirm,
    confirmation,
    show,
  };
}
