<script setup>
import PSpinningLoader from "@/Library/components/Base/PSpinningLoader.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ArrowDownTrayIcon, ArrowTopRightOnSquareIcon } from "@heroicons/vue/16/solid";
import {
  ArrowLeftStartOnRectangleIcon,
  ChevronRightIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/20/solid";
import { Link, router, usePage } from "@inertiajs/vue3";
import pluralize from "pluralize";
import { computed, onMounted, onUnmounted, provide, reactive, ref } from "vue";

const event = computed(() => usePage().props.event);
const sponsorships = computed(() => usePage().props.sponsorships ?? {});
const supplier = computed(() => usePage().props?.supplier ?? {});
const suppliers = computed(() => usePage().props.event?.approved_suppliers ?? []);
const category = computed(() => usePage().props?.category ?? null);
const loading = ref(false);
const user = computed(() => usePage().props.auth.user);
const search = ref("");

const currentIndex = ref(Math.floor(Math.random() * sponsorships.value.presenting_sponsors?.length));
let intervalId = null;

onMounted(() => {
  intervalId = setInterval(() => {
    currentIndex.value = Math.floor(Math.random() * sponsorships.value.presenting_sponsors?.length);
  }, 8000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});

// create a search computed property that will filter through suppliers.value name based on the search value
const filteredSuppliers = computed(() => {
  if (search.value === "") {
    return suppliers.value;
  }
  return suppliers.value.filter((supplier) => {
    return supplier.invitation.name.toLowerCase().includes(search.value.toLowerCase());
  });
});

const navigation = [
  { name: "Lobby", route: "events.event.show.lobby.index", component: "Events/Event/Show/Lobby/Index" },
  ...(event.value.metadata.content.documents?.event_schedule_url
    ? [
        {
          name: "Show Schedule",
          url: event.value.metadata.content.documents?.event_schedule_url,
          // download: true,
        },
      ]
    : []),
  // ...(event.value?.metadata.content.documents?.buyers_guide_url
  //   ? [
  //       {
  //         name: "Buyers Guide Preview",
  //         url: event.value?.metadata.content.documents.buyers_guide_url,
  //         download: true,
  //       },
  //     ]
  //   : []),
  {
    name: event.value.in_person ? "Registered Suppliers" : "Show Floor",
    route: "events.event.show.show-floor.index",
    component: "Events/Event/Show/ShowFloor/Index",
    // disabled: !event.value.live ? true : false, // Keep this commented out until we figure out what we want to do when we grow up...
  }, // If this is a virtual event and we're not live disable.
  {
    name: "Show Specials",
    route: "events.event.show.lobby.index",
    component: "Events/Event/Show/ShowSpecials/Index",
    children: [
      ...(event.value?.has_show_only_buys
        ? [
            {
              name: pluralize(event.value.event_type.show_only_buy_label),
              route: "events.event.show.show-specials.show-only-buys.category.index",
              component: "Events/Event/Show/ShowSpecials/ShowOnlyBuys/",
              disabled: event.value?.show_only_buys_ended,
            },
          ]
        : []),
      ...(event.value?.has_show_specials
        ? [
            {
              name: "30/30 Specials",
              route: "events.event.show.show-specials.specials.category.index",
              component: "Events/Event/Show/ShowSpecials/Specials/",
              disabled: event.value?.show_specials_ended,
            },
          ]
        : []),
      ...(event.value?.has_paddle_buys
        ? [
            {
              name: "Paddle Buys",
              route: "events.event.show.show-specials.paddle-buys.index",
              component: "Events/Event/Show/ShowSpecials/PaddleBuys/",
              disabled: event.value?.paddle_buys_ended,
            },
          ]
        : []),
      {
        name: "Reports",
        route: "events.event.show.show-specials.reports.index",
        component: "Events/Event/Show/ShowSpecials/Reports",
      },
    ],
  },
  ...(event.value?.has_new_items
    ? [
        {
          name: `${event.value.event_type.new_item_label} Room`,
          route: "events.event.show.new-item-room.category.index",
          component: "Events/Event/Show/NewItemRoom/",
          disabled: event.value?.new_items_ended,
        },
      ]
    : []),
  {
    name: event.in_person ? "New Suppliers Registered" : "New Suppliers",
    route: "events.event.show.new-supplier-aisle.index",
    component: "Events/Event/Show/NewSupplierAisle/Index",
    // disabled: !event.value.live ? true : false, // Keep this commented out until we figure out what we want to do when we grow up...
  },
  // { name: "Member Lounge", route: "events.event.show.lobby.index", component: "Events/Event/Show/MemberLounge/Index" },
  // {
  //   name: "Presentations",
  //   route: "events.event.show.presentations.index",
  //   component: "Events/Event/Show/Presentations/Index",
  // },
  // {
  //   name: "Messages",
  //   route: "events.event.show.presentations.index",
  //   component: "Events/Event/Show/Presentations/Index",
  // },
  // {
  //   name: "Commitments",
  //   route: "events.event.show.extras.commitments.index",
  //   component: "Events/Event/Show/Extras/Commitments/Index",
  // },
  // ...(event.value?.has_show_only_buys
  //   ? [
  //       {
  //         name: "SOB Commitments",
  //         url: route("events.event.show.extras.commitments.index", { event: event.value.id, type: "sob" }),
  //         download: true,
  //       },
  //     ]
  //   : []),
  // ...(event.value?.has_paddle_buys
  //   ? [
  //       {
  //         name: "Paddle Buy Commitments",
  //         url: route("events.event.show.extras.commitments.index", { event: event.value.id, type: "paddle" }),
  //         download: true,
  //       },
  //     ]
  //   : []),
  // {
  //   name: "Sell Sheets",
  //   route: "events.event.show.show-specials.sell-sheets.index",
  //   component: "Events/Event/Show/ShowSpecials/SellSheets/Index",
  // },
];

const state = reactive({
  isMessagesOpen: false,
  toggleMessages: (value) => {
    state.isMessagesOpen = value;
  },
});
provide("layoutState", state);

const showSidebar = ref(false);
const isLive = computed(() => event.value.live);
const isPreviewOpen = computed(() => event.value.preview_open);
const isArchived = computed(() => event.value.archived);

router.on("navigate", (event) => {
  showSidebar.value =
    !event.detail.page.component.startsWith("Events/Event/Show/Lobby") &&
    !event.detail.page.component.startsWith("Events/Event/Show/ShowSpecials/Reports") &&
    (isLive.value || isPreviewOpen.value || isArchived.value);
});
</script>

<template>
  <div class="flex h-full">
    <aside class="bg-slate-900/95 border-l border-slate-700/30 h-full w-64">
      <div class="flex- flex flex-col overflow-y-auto">
        <nav class="flex flex-1 flex-col gap-y-5 px-4 py-6 sm:px-6">
          <ul class="flex flex-1 flex-col gap-y-7" role="list">
            <li>
              <ul class="-mx-2 space-y-1" role="list">
                <li v-if="user.type === 'admin'">
                  <Link
                    :href="route('events.event.show', { event: event.id })"
                    class="group flex gap-x-3 items-center rounded-md px-3 py-2 text-sm leading-6 font-medium text-slate-400 hover:text-white hover:bg-slate-700/50"
                  >
                    <ArrowLeftStartOnRectangleIcon class="size-5" />
                    Back to Admin
                  </Link>
                </li>
                <li v-for="(item, index) in navigation" :key="item.name">
                  <a
                    v-if="item.url"
                    :href="item.url"
                    class="group flex gap-x-3 items-center rounded-md justify-between px-3 py-2 text-sm leading-6 font-medium text-slate-400 hover:text-white hover:bg-slate-700/50"
                    target="_blank"
                  >
                    {{ item.name }}
                    <ArrowDownTrayIcon v-if="item.download" class="-ml-0.5 h-4 w-4" />
                    <ArrowTopRightOnSquareIcon v-else class="-ml-0.5 h-4 w-4" />
                  </a>
                  <Link
                    v-else-if="item.route && !item.children && !item.disabled"
                    :class="[
                      $page.component.startsWith(item.component)
                        ? 'bg-slate-900 text-white'
                        : 'text-slate-400 hover:text-white hover:bg-slate-700/50',
                      'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium',
                      item.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    ]"
                    :href="item.disabled ? '#' : route(item.route, { event: $page.props.event.id })"
                  >
                    {{ item.name }}
                  </Link>
                  <span
                    v-else-if="item.route && !item.children && item.disabled"
                    :class="[
                      'text-slate-500 cursor-not-allowed',
                      'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium',
                    ]"
                  >
                    {{ item.name }}
                  </span>
                  <Disclosure v-else-if="item.route && item?.children" v-slot="{ open }" :defaultOpen="false" as="div">
                    <DisclosureButton
                      class="text-slate-400 hover:bg-slate-700/50 hover:text-white group w-full flex items-center px-3 py-2 text-left text-sm font-medium rounded-md focus:outline-none justify-between"
                    >
                      <span class="flex items-center gap-3">
                        <span>{{ item.name }}</span>
                      </span>
                      <ChevronRightIcon
                        :class="[
                          open ? 'rotate-90' : '',
                          'text-slate-600 ml-3 flex-shrink-0 h-5 w-5 transform transition-all duration-200',
                        ]"
                      />
                    </DisclosureButton>
                    <transition
                      enter-active-class="transition duration-200 ease-out"
                      enter-from-class="transform scale-95 opacity-0"
                      enter-to-class="transform scale-200 opacity-200"
                      leave-active-class="transition duration-75 ease-out"
                      leave-from-class="transform scale-200 opacity-200"
                      leave-to-class="transform scale-95 opacity-0"
                    >
                      <DisclosurePanel class="py-1">
                        <template v-for="item in item.children" :key="item.name">
                          <a
                            v-if="item.url"
                            :href="item.url"
                            class="group flex gap-x-3 items-center rounded-md px-3 justify-between py-2 pl-6 text-sm font-medium text-slate-400 hover:text-white hover:bg-slate-700/50"
                            target="_blank"
                          >
                            {{ item.name }}
                            <ArrowDownTrayIcon v-if="item.download" class="-ml-0.5 h-4 w-4" />
                            <ArrowTopRightOnSquareIcon v-else class="-ml-0.5 h-4 w-4" />
                          </a>
                          <Link
                            v-else-if="!item.disabled"
                            :class="[
                              $page.component.startsWith(item.component)
                                ? 'bg-slate-900 text-white'
                                : 'text-slate-400 hover:bg-slate-700/50 hover:text-white',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-medium pl-6',
                            ]"
                            :href="route(item.route, { event: $page.props.event.id })"
                          >
                            {{ item.name }}
                          </Link>
                          <span
                            v-else-if="item.disabled"
                            :class="[
                              'group flex gap-x-3 items-center rounded-md px-3 justify-between py-2 pl-6 text-sm',
                              'font-medium text-slate-500 cursor-not-allowed',
                            ]"
                          >
                            {{ item.name }}
                          </span>
                        </template>
                      </DisclosurePanel>
                    </transition>
                  </Disclosure>
                  <button
                    v-else
                    :class="[
                      $page.component.startsWith(item.component)
                        ? 'bg-slate-900 text-white'
                        : 'text-slate-400 hover:text-white hover:bg-slate-700/50',
                      'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium w-full',
                    ]"
                    @click="item.action"
                  >
                    {{ item.name }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>

    <aside v-if="showSidebar" class="h-full w-56 overflow-y-auto border-r border-slate-300 bg-slate-50">
      <div class="flex flex-col text-sm leading-6 font-medium text-slate-400 px-3">
        <div v-if="sponsorships.category_title_sponsor" class="relative">
          <img :src="`/img/events/sponsorships/category-banners/${category.slug}.png`" class="w-full h-auto" />
          <div class="absolute flex justify-center items-center inset-0">
            <img
              v-if="sponsorships.category_title_sponsor"
              :src="sponsorships.category_title_sponsor.location_url"
              alt=""
              class="w-48"
            />
          </div>
        </div>
        <img
          v-if="sponsorships?.presenting_sponsors?.length"
          :src="sponsorships.presenting_sponsors[currentIndex]?.location_url"
          alt=""
          class="w-48 mx-auto pt-2"
        />
        <PSpinningLoader v-if="loading" class="h-12 w-12 mx-auto my-20" />
        <div v-else class="py-4">
          <div>
            <div class="relative rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon aria-hidden="true" class="h-5 w-5 text-slate-400" />
              </div>
              <input
                id="search"
                v-model="search"
                class="block w-full rounded-md border-0 py-1.5 pl-10 text-slate-900 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
                name="search"
                placeholder="Search..."
                type="search"
              />
            </div>
          </div>
          <ul class="pt-4">
            <li
              v-for="filteredSupplier in filteredSuppliers"
              :key="filteredSupplier.id"
              :class="{ 'bg-slate-200/50 font-bold': filteredSupplier?.id === supplier?.id }"
              class="rounded-md px-3 py-2 text-sm leading-6 text-slate-900 hover: hover:text-primary-500 cursor-pointer"
            >
              <Link
                :href="
                  route('events.event.show.show-floor.category.index', {
                    event: event,
                    blue_book_category: category || 'all',
                    supplier: filteredSupplier.id,
                  })
                "
              >
                {{ filteredSupplier.invitation.name }}
              </Link>
            </li>
            <li
              v-if="filteredSuppliers.length === 0"
              class="px-3 py-2 text-sm flex flex-col justify-center items-center"
            >
              <ListBulletIcon class="text-slate-500 h-5 w-5" />
              <span class="mt-1 text-slate-700 font-medium">No results.</span>
            </li>
          </ul>
        </div>
      </div>
    </aside>

    <div class="flex-1 h-full overflow-auto">
      <slot></slot>
    </div>
  </div>
</template>
