import { computed, reactive, ref } from "vue";

let show = ref(false);
const notification = reactive({
  title: "Notification Title",
  message: "Notification Message",
  duration: 14000,
  type: "success",
});
let timeout = ref(null);
export default function useNotification() {
  const isShowing = computed(() => {
    return show.value;
  });

  const notify = ({ title, message, type, duration }) => {
    if (show.value) {
      show.value = false;
    }
    if (timeout.value) {
      clearTimeout(timeout.value);
    }
    notification.title = title || notification.title;
    notification.message = message || notification.message;
    notification.type = type || notification.type;
    notification.duration = duration || notification.duration;
    show.value = true;

    timeout.value = setTimeout(() => {
      dismiss();
    }, notification.duration);
  };

  const dismiss = () => {
    show.value = false;
  };

  const isSuccess = computed(() => notification.type === "success");
  const isError = computed(() => notification.type === "error");
  const isWarning = computed(() => notification.type === "warning");

  return {
    dismiss,
    isError,
    isSuccess,
    isShowing,
    isWarning,
    notify,
    notification,
  };
}
