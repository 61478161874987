<template>
  <main
    :class="{
      'max-w-full mx-auto px-4 sm:px-6 md:px-8 pb-16': !$attrs.class,
    }"
  >
    <TransitionRoot
      appear
      :show="isShowing"
      enter="transition duration-300 delay-100 ease-in"
      enter-from="opacity-0 translate-y-full"
      enter-to="opacity-100 translate-y-0"
      leave="transition duration-300"
      leave-from="opacity-100 translate-y-full"
      leave-to="opacity-0 translate-y-0"
    >
      <slot></slot>
    </TransitionRoot>
  </main>
</template>

<script setup>
import { ref } from "vue";
import { TransitionRoot } from "@headlessui/vue";

const isShowing = ref(true);
</script>
