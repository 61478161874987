<template>
  <svg v-if="withText" viewBox="0 0 504 107.03" xmlns="http://www.w3.org/2000/svg">
    <title>logo</title>
    <polygon :class="fill1" points="32.37 47.93 54.95 67.66 49.9 45.45 29.39 26.87 32.37 47.93" />
    <polygon :class="fill1" points="63.88 18.78 53.67 44.8 58.7 66.76 70.57 39.75 63.88 18.78" />
    <path
      :class="fill4"
      d="M299.88,53.14c-2.65-2.22-6.79-3.94-12.3-5.12A48,48,0,0,1,281,46.27a14.23,14.23,0,0,1-3.87-2A5.68,5.68,0,0,1,275.36,42a7.51,7.51,0,0,1-.51-2.86V39a6.3,6.3,0,0,1,2.55-5.11,11.15,11.15,0,0,1,7.19-2.12,20.4,20.4,0,0,1,7,1.22,23.32,23.32,0,0,1,6.68,3.94l0.51,0.42,4-5.28-0.49-.39a29.37,29.37,0,0,0-7.95-4.59,27.48,27.48,0,0,0-9.6-1.54,20.27,20.27,0,0,0-6.62,1,17,17,0,0,0-5.28,2.9,13.15,13.15,0,0,0-3.52,4.43,12.66,12.66,0,0,0-1.25,5.57v0.14a13.62,13.62,0,0,0,1,5.48,11.17,11.17,0,0,0,3.18,4.14,18.9,18.9,0,0,0,5.32,3A51.57,51.57,0,0,0,285,54.36a49.46,49.46,0,0,1,6.24,1.72,13.31,13.31,0,0,1,3.7,1.92,5.62,5.62,0,0,1,1.76,2.22A7.31,7.31,0,0,1,297.2,63v0.14a6.6,6.6,0,0,1-2.72,5.46A11.87,11.87,0,0,1,287,70.8a21.71,21.71,0,0,1-8.73-1.66,28.25,28.25,0,0,1-7.56-5.08l-0.49-.45-4.24,5,0.46,0.42a31.36,31.36,0,0,0,9.43,6,29.62,29.62,0,0,0,10.93,2,22.72,22.72,0,0,0,6.88-1,16,16,0,0,0,5.46-2.9,13.7,13.7,0,0,0,3.58-4.57,13.49,13.49,0,0,0,1.3-6V62.45A11.55,11.55,0,0,0,299.88,53.14Z"
      transform="translate(0 -0.31)"
    />
    <polygon
      :class="fill4"
      points="345.45 25.96 305.91 25.96 305.91 32.32 322.29 32.32 322.29 76.02 329.07 76.02 329.07 32.32 345.45 32.32 345.45 25.96"
    />
    <polygon
      :class="fill4"
      points="381.87 32.32 398.25 32.32 398.25 76.02 405.04 76.02 405.04 32.32 421.41 32.32 421.41 25.96 381.87 25.96 381.87 32.32"
    />
    <path
      :class="fill4"
      d="M499.9,53.14c-2.65-2.22-6.78-3.94-12.3-5.12a48,48,0,0,1-6.53-1.75,14.22,14.22,0,0,1-3.87-2A5.68,5.68,0,0,1,475.38,42a7.51,7.51,0,0,1-.51-2.86V39a6.3,6.3,0,0,1,2.55-5.11,11.15,11.15,0,0,1,7.19-2.12,20.4,20.4,0,0,1,7,1.22,23.33,23.33,0,0,1,6.68,3.94l0.51,0.42,4-5.28-0.49-.39a29.36,29.36,0,0,0-7.95-4.59,27.48,27.48,0,0,0-9.6-1.54,20.26,20.26,0,0,0-6.62,1,17,17,0,0,0-5.29,2.9,13.14,13.14,0,0,0-3.52,4.43,12.65,12.65,0,0,0-1.25,5.57v0.14a13.62,13.62,0,0,0,1,5.48,11.15,11.15,0,0,0,3.18,4.14,18.84,18.84,0,0,0,5.31,3A51.44,51.44,0,0,0,485,54.36a49.69,49.69,0,0,1,6.24,1.72,13.34,13.34,0,0,1,3.7,1.92,5.62,5.62,0,0,1,1.76,2.22,7.31,7.31,0,0,1,.51,2.79v0.14a6.6,6.6,0,0,1-2.72,5.46A11.86,11.86,0,0,1,487,70.8a21.72,21.72,0,0,1-8.73-1.66,28.22,28.22,0,0,1-7.56-5.08l-0.49-.45-4.24,5,0.46,0.42a31.34,31.34,0,0,0,9.43,6,29.63,29.63,0,0,0,10.93,2,22.71,22.71,0,0,0,6.88-1,16.06,16.06,0,0,0,5.46-2.9,13.74,13.74,0,0,0,3.58-4.57,13.48,13.48,0,0,0,1.3-6V62.45A11.55,11.55,0,0,0,499.9,53.14Z"
      transform="translate(0 -0.31)"
    />
    <rect :class="fill4" height="50.06" width="6.78" x="185.9" y="25.26" />
    <polygon
      :class="fill4"
      points="152.39 51.37 134.96 25.26 128.4 25.26 128.4 75.32 135.04 75.32 135.04 37.31 151.84 61.94 152.79 61.94 169.59 37.24 169.59 75.32 176.37 75.32 176.37 25.26 169.82 25.26 152.39 51.37"
    />
    <path
      :class="fill2"
      d="M53.45,0.31a53.52,53.52,0,1,0,53.45,53.52A53.48,53.48,0,0,0,53.45.31Zm0,102.11A48.87,48.87,0,0,1,7.28,37.54L17,35.29l13,56.41,13.25-3.06L29.39,27.18,9.73,31.73A48.88,48.88,0,0,1,99.37,70.32l-9,2.07L77.38,16,63.88,19.09,78.13,80.48l18.63-4.3A48.85,48.85,0,0,1,53.45,102.42Z"
      transform="translate(0 -0.31)"
    />
    <polygon :class="fill4" points="251.94 47.32 249.14 54.01 263.12 54.01 265.99 47.32 251.94 47.32" />
    <path
      :class="fill4"
      d="M244.41,40.63a23.56,23.56,0,0,0-5.48-7.91,25.62,25.62,0,0,0-8.4-5.25,29.55,29.55,0,0,0-10.76-1.89H202.2V66.4H209V31.93h10.79a21.9,21.9,0,0,1,8.13,1.45,18.09,18.09,0,0,1,6.16,4,17.77,17.77,0,0,1,3.9,5.92,19.38,19.38,0,0,1,1.38,7.32v0.14A19.38,19.38,0,0,1,238,58.06a17.37,17.37,0,0,1-3.9,5.88,17.92,17.92,0,0,1-6.16,3.9,22.37,22.37,0,0,1-8.13,1.42H202.2v6.36h17.57a29.06,29.06,0,0,0,10.76-1.93,26.08,26.08,0,0,0,8.39-5.29,23.46,23.46,0,0,0,5.48-8,25.22,25.22,0,0,0,1.93-9.87V50.46A25,25,0,0,0,244.41,40.63Z"
      transform="translate(0 -0.31)"
    />
    <polygon
      :class="fill4"
      points="366.5 25.61 360.52 25.61 337.71 76.02 344.76 76.02 363.44 34.23 373.54 56.9 356.43 56.9 353.67 63.13 376.26 63.13 381.98 76.02 389.31 76.02 366.5 25.61"
    />
    <polygon
      :class="fill4"
      points="432.91 69.65 432.91 32.32 462.67 32.32 462.67 25.96 426.13 25.96 426.13 76.02 463.02 76.02 463.02 69.65 432.91 69.65"
    />
    <rect :class="fill4" height="6.37" width="23.68" x="435.85" y="47.56" />
  </svg>
  <svg v-else viewBox="0 0 287.65 288" xmlns="http://www.w3.org/2000/svg">
    <title>logo-icon</title>
    <polygon :class="fill1" points="87.08 128.98 147.87 182.05 134.27 122.29 79.08 72.3 87.08 128.98" />
    <polygon :class="fill1" points="171.89 50.52 144.42 120.56 157.94 179.63 189.87 106.95 171.89 50.52" />
    <path
      :class="fill2"
      d="M143.83,0.33C64.39,0.33,0,64.8,0,144.33s64.39,144,143.83,144,143.82-64.47,143.82-144S223.26,0.33,143.83.33Zm0,274.75A131.49,131.49,0,0,1,19.59,100.51l26.26-6.06,35,151.78L116.55,238,79.08,72.63,26.17,84.88A131.51,131.51,0,0,1,267.37,188.7l-24.12,5.57L208.2,42.43l-36.31,8.41L210.23,216l50.14-11.57A131.45,131.45,0,0,1,143.83,275.08Z"
      transform="translate(0 -0.33)"
    />
  </svg>
</template>
<script setup>
import { toRefs } from "vue";

const props = defineProps({
  withText: {
    type: Boolean,
    default: false,
  },
  fill1: {
    type: String,
    default: "fill-red-900",
  },
  fill2: {
    type: String,
    default: "fill-red-800",
  },
  fill3: {
    type: String,
    default: "fill-white",
  },
  fill4: {
    type: String,
    default: "fill-yellow-900",
  },
});

const { fill1, fill2, fill3, fill4, withText } = toRefs(props);
</script>
