import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    address: "",
    city: "",
    company_id: "",
    company_name: "",
    company_account_number: "",
    email_address: "",
    extension: "",
    fax_number: "",
    id: "",
    name: "",
    phone_number: "",
    title: "",
    type: "",
    token: "",
    eventId: "",
    eventToken: "",
  }),
  getters: {
    firstName: (state) => state.name.split(" ")[0],
  },
  actions: {
    set(user = {}) {
      this.$patch(user);
    },
    reset() {
      this.$reset();
    },
  },
});
