import { useNotification } from "@/Library";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useInvoiceResearchRecordSelectListStore = defineStore("invoiceResearchRecordSelectListStore", () => {
  const { notify } = useNotification();
  const records = ref([]);

  const hasRecords = computed(() => records.value.length > 0);

  const exists = (record) => records.value.some((el) => el.id === record.id);
  const reset = () => (records.value = []);

  function maybeAddRecord(record) {
    const pointer = records.value.findIndex((existingRecord) => existingRecord.id === record.id);
    pointer === -1 ? records.value.push(record) : records.value.splice(pointer, 1);
  }

  async function download() {
    try {
      const { data } = await axios.post(route(`member.invoice-research-download.store`), { records: records.value });
      reset();
      window.location.replace(data.url);
      notify({
        title: `Invoice download successful`,
        message: `Invoices have been downloaded successfully to your computer.`,
        type: "success",
      });
    } catch (error) {
      notify({
        title: `Invoice download failed`,
        message: `There was a problem downloading the invoices. Please try again.`,
        type: "error",
      });
    }
  }

  return {
    records,
    hasRecords,
    download,
    exists,
    reset,
    maybeAddRecord,
  };
});
