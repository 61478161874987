import { usePage } from "@inertiajs/vue3";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

const onlineUsers = ref([]);
const supplier = computed(() => usePage().props.supplier);

const whisperUserBusy = (id) => {
  makeUserBusy(id);
  Echo.join("onlineUsers").whisper("userBusy", { id: id });
};

const makeUserBusy = (id) => {
  const index = onlineUsers.value.findIndex((u) => u.id === id);
  if (index >= 0) {
    onlineUsers.value[index].status = "busy";
  }
};

const whisperUserAvailable = (id) => {
  makeUserAvailable(id);
  Echo.join("onlineUsers").whisper("userAvailable", { id: id });
};

const makeUserAvailable = (id) => {
  const index = onlineUsers.value.findIndex((u) => u.id === id);
  if (index >= 0) {
    onlineUsers.value[index].status = "available";
  }
};

const setCallHistory = (e) => {
  const index = onlineUsers.value.findIndex((u) => u.id === e.caller.id);
  if (index === -1) {
    return;
  }
  if (!onlineUsers.value[index]?.callHistory) {
    onlineUsers.value[index].callHistory = [];
  }
  if (supplier.value) {
    onlineUsers.value[index].callHistory.push(supplier.value.id);
  }
};

const hasCalled = (caller, supplier) => {
  const index = onlineUsers.value.findIndex((u) => u.id === caller.id);
  return onlineUsers.value[index]?.callHistory?.includes(supplier.id);
};

export const useOnlineUserStore = defineStore("onlineUserStore", () => {
  const isUserOnline = (user) => {
    return onlineUsers.value.some((u) => u.id === user.id);
  };

  return {
    onlineUsers,
    isUserOnline,
    whisperUserBusy,
    makeUserBusy,
    whisperUserAvailable,
    makeUserAvailable,
    hasCalled,
  };
});

export const install = () => {
  Echo.join("onlineUsers")
    .here((users) => {
      // Initialize online users with a list of users already in this channel
      onlineUsers.value = users;
    })
    .joining((user) => {
      // Add any new users who join the channel
      onlineUsers.value.push(user);
    })
    .leaving((user) => {
      // Remove any users who leave the channel
      const index = onlineUsers.value.findIndex((u) => u.id === user.id);
      onlineUsers.value.splice(index, 1);
    })
    .listen("IncomingCall", (e) => {
      makeUserBusy(e.callee.id);
      setCallHistory(e);
    })
    .listen("DeclineCall", (e) => {
      makeUserAvailable(e.callee.id);
    })
    .listenForWhisper("userBusy", (e) => {
      makeUserBusy(e.id);
    })
    .listenForWhisper("userAvailable", (e) => {
      makeUserAvailable(e.id);
    })
    .listenForWhisper("callAnswered", (e) => {
      makeUserBusy(e.id);
    });

  useOnlineUserStore();
};
