<template>
  <TransitionRoot :show="modelValue" as="template" @after-leave="query = ''">
    <Dialog as="div" class="relative z-20" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-20 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-3xl transform divide-y divide-slate-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox
              as="div"
              class="mx-auto max-w-3xl transform divide-y divide-slate-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
            >
              <div class="relative flex items-center">
                <MagnifyingGlassIcon
                  class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-slate-400"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-slate-800 placeholder-slate-400 focus:ring-0 sm:text-sm"
                  placeholder="Search Site"
                  autocomplete="off"
                  @change="query = $event.target.value"
                />
                <PSpinningLoader v-if="loading" class="text-primary-600 fill-current h-6 w-6 mr-4" />
              </div>

              <div
                v-if="!query || !entityGroups.length"
                class="border-t border-slate-100 py-14 px-6 text-center text-sm sm:px-14"
              >
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="mx-auto h-12 w-12 text-slate-400"
                  aria-hidden="true"
                >
                  <path
                    vector-effect="non-scaling-stroke"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64"
                  />
                </svg>
                <p class="mt-4 font-semibold text-slate-900">Search for contacts, members, and suppliers</p>
                <p class="mt-2 text-slate-500">
                  Quickly access contacts, members, and suppliers by running a global search.
                </p>
              </div>

              <ComboboxOptions
                v-else-if="entityGroups.some((eg) => eg.entities.length)"
                static
                class="max-h-80 scroll-pt-11 scroll-pb-2 space-y-2 overflow-y-auto pb-2"
              >
                <li v-for="entityGroup in entityGroups.filter((eg) => eg.entities.length)" :key="entityGroup.group">
                  <h2 class="bg-slate-100 py-2.5 px-4 text-xs font-semibold text-slate-900 capitalize">
                    {{ entityGroup.group }}
                  </h2>
                  <ul class="mt-2 text-sm text-slate-800">
                    <ComboboxOption
                      v-for="entity in entityGroup.entities"
                      :key="entity.id"
                      :value="entity"
                      as="template"
                      v-slot="{ active }"
                    >
                      <li
                        @click="selectEntity(entity, entityGroup.group)"
                        :class="[
                          'group flex cursor-pointer select-none items-center px-4 py-2 hover:bg-primary-600 hover:text-white',
                          active && 'bg-primary-600 text-white',
                        ]"
                      >
                        {{ entity.name }}
                        <span class="text-sm text-slate-400"> &nbsp;{{ entity.company_name }}</span>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>

              <div
                v-else-if="query && entityGroups.length && entityGroups.every((eg) => !eg.entities.length)"
                class="border-t border-slate-100 py-14 px-6 text-center text-sm sm:px-14"
              >
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="mx-auto h-12 w-12 text-slate-400"
                  aria-hidden="true"
                >
                  <path
                    vector-effect="non-scaling-stroke"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                  />
                </svg>
                <p class="mt-4 font-semibold text-slate-900">No results</p>
                <p class="mt-2 text-slate-500">We couldn’t find anything with that name. Please try again.</p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogOverlay,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import { router, usePage } from "@inertiajs/vue3";
import { PSpinningLoader } from "@Library";
import { onKeyStroke } from "@vueuse/core";
import { ref, toRefs, watch } from "vue";

const props = defineProps({
  modelValue: { type: Boolean, default: false },
});
const emit = defineEmits(["update:modelValue"]);
const { modelValue } = toRefs(props);
const loading = ref(false);
const query = ref("");
const searchTimeout = ref(null);
const entityGroups = ref([]);
const user = usePage().props.auth.user;

const search = async () => {
  try {
    loading.value = true;
    const res = await axios.get("/api/global-search", { params: { search: query.value, show: 25 } });
    entityGroups.value = res.data;
    loading.value = false;
  } catch (e) {
    entityGroups.value = [];
    loading.value = false;
  }
};
const selectEntity = (entity, group) => {
  if (group.slice(0, -1) === "member") {
    router.get(route(`${user.type}.members.show`, entity.id));
  } else if (group.slice(0, -1) === "vendor") {
    router.get(route(`${user.type}.vendors.show`, entity.id));
  } else {
    router.get(route(`contacts.show`, entity.id));
  }
  close();
};

onKeyStroke(["Control", "k"], (e) => {
  if (e.ctrlKey && e.key === "k") {
    e.preventDefault();
    emit("update:modelValue", true);
  }
});

watch(
  () => query.value,
  (newVal) => {
    if (newVal) {
      clearTimeout(searchTimeout.value);
      searchTimeout.value = setTimeout(() => {
        if (query.value !== "") {
          search();
        }
      }, 1000);
    } else {
      entityGroups.value = [];
    }
  }
);

const close = () => {
  emit("update:modelValue", false);
};
</script>
