export const TRANSITIONS = {
  overlay: {
    enterFromClass: "opacity-0 scale-75",
    enterActiveClass: "transition-transform transition-opacity duration-150 ease-in",
    leaveActiveClass: "transition-opacity duration-150 ease-linear",
    leaveToClass: "opacity-0",
  },
};

export default {
  datatable: {
    root: ({ props }) => ({
      class: [
        "relative",
        {
          "flex flex-col h-full": props.scrollable && props.scrollHeight === "flex",
        },
      ],
    }),
    loadingoverlay: {
      class: [
        "fixed w-full h-full t-0 l-0 bg-slate-50/80",
        "transition duration-200",
        "absolute flex items-center justify-center z-10",
        "dark:bg-slate-950/40", // Dark Mode
      ],
    },
    loadingicon: "w-8 h-8",
    wrapper: ({ props }) => ({
      class: [
        {
          relative: props.scrollable,
          "flex flex-col grow h-full": props.scrollable && props.scrollHeight === "flex",
        },
      ],
    }),
    header: ({ props }) => ({
      class: [
        "bg-slate-50 text-slate-700 border-slate-300 p-4",
        "dark:border-blue-900/40 dark:text-white/80 dark:bg-slate-900", // Dark Mode
        props.showGridlines ? "border-x border-t border-b-0" : "border-b border-x-0",
      ],
    }),
    table: "w-full border-spacing-0 divide-y divide-slate-300",
    thead: ({ context }) => ({
      class: [
        {
          "bg-slate-50 top-0 z-[1]": context.scrollable,
        },
      ],
    }),
    tbody: ({ instance, context }) => ({
      class: [
        {
          "sticky z-[1]": instance.frozenRow && context.scrollable,
        },
      ],
    }),
    tfoot: ({ context }) => ({
      class: [
        {
          "bg-slate-50 bottom-0 z-[1]": context.scrollable,
        },
      ],
    }),
    footer: {
      class: [
        "bg-slate-50 text-slate-700 border-t-0 border-b border-x-0 border-slate-300 font-bold p-4",
        "dark:border-blue-900/40 dark:text-white/80 dark:bg-slate-900", // Dark Mode
      ],
    },
    column: {
      headercell: ({ context, props }) => ({
        class: [
          "text-left border-0 border-b border-solid border-slate-300 dark:border-blue-900/40 font-semibold text-sm",
          "transition duration-200",
          context?.size === "small"
            ? "p-2"
            : context?.size === "large"
            ? "p-5"
            : "first:pl-4 first:pr-3 first:sm:pl-6 px-3 py-3.5 last:pl-3 last:pr-4 last:sm:pr-6 last:relative", // Size
          context.sorted ? "bg-primary-50 text-primary-700" : "bg-slate-50 text-slate-900", // Sort
          context.sorted ? "dark:text-white/80 dark:bg-blue-300" : "dark:text-white/80 dark:bg-slate-900", // Dark Mode
          {
            "sticky z-[1]": props.frozen || props.frozen === "", // Frozen Columns
            "border-x border-y": context?.showGridlines,
            "overflow-hidden space-nowrap border-y relative bg-clip-padding": context.resizable, // Resizable
          },
        ],
      }),
      headercontent: "flex items-center",
      bodycell: ({ props, context }) => ({
        class: [
          "text-left border-0 border-b border-solid border-slate-200 text-sm whitespace-nowrap",
          context?.size === "small"
            ? "p-2"
            : context?.size === "large"
            ? "p-5"
            : "first:pl-4 first:pr-3 first:sm:pl-6 first:font-medium first:text-slate-900 px-3 py-4 last:pl-3 last:pr-4 last:text-right last:relative", // Size
          "dark:text-white/80 dark:border-blue-900/40", // Dark Mode
          {
            "sticky bg-inherit": props.frozen || props.frozen === "", // Frozen Columns
            "border-x border-y": context?.showGridlines,
          },
        ],
      }),
      footercell: ({ context }) => ({
        class: [
          "text-left border-0 border-b border-solid border-slate-300 font-bold",
          "bg-slate-50 text-slate-700",
          "transition duration-200",
          context?.size === "small" ? "p-2" : context?.size === "large" ? "p-5" : "p-4", // Size
          "dark:text-white/80 dark:bg-slate-900 dark:border-blue-900/40", // Dark Mode
          {
            "border-x border-y": context?.showGridlines,
          },
        ],
      }),
      sorticon: ({ context }) => ({
        class: ["ml-2", context.sorted ? "text-primary-700 dark:text-white/80" : "text-slate-700 dark:text-white/70"],
      }),
      sortbadge: {
        class: [
          "flex items-center justify-center align-middle",
          "rounded-[50%] w-[1.143rem] leading-[1.143rem] ml-2",
          "text-blue-700 bg-blue-50",
          "dark:text-white/80 dark:bg-blue-400", // Dark Mode
        ],
      },
      columnfilter: "inline-flex items-center ml-auto",
      filteroverlay: {
        class: [
          "bg-white text-slate-600 border-0 rounded-md min-w-[12.5rem]",
          "dark:bg-slate-900 dark:border-blue-900/40 dark:text-white/80", //Dark Mode
        ],
      },
      filtermatchmodedropdown: {
        root: "min-[0px]:flex mb-2",
      },
      filterrowitems: "m-0 p-0 py-3 list-none ",
      filterrowitem: ({ context }) => ({
        class: [
          "m-0 py-3 px-5 bg-transparent",
          "transition duration-200",
          context?.highlighted
            ? "text-blue-700 bg-blue-100 dark:text-white/80 dark:bg-blue-300"
            : "text-slate-600 bg-transparent dark:text-white/80 dark:bg-transparent",
        ],
      }),
      filteroperator: {
        class: [
          "px-5 py-3 border-b border-solid border-slate-300 text-slate-700 bg-slate-50 rounded-t-md",
          "dark:border-blue-900/40 dark:text-white/80 dark:bg-slate-900", // Dark Mode
        ],
      },
      filteroperatordropdown: {
        root: "min-[0px]:flex",
      },
      filterconstraint: "p-5 border-b border-solid border-slate-300 dark:border-blue-900/40",
      filteraddrule: "py-3 px-5",
      filteraddrulebutton: {
        root: "justify-center w-full min-[0px]:text-sm",
        label: "flex-auto grow-0",
        icon: "mr-2",
      },
      filterremovebutton: {
        root: "ml-2",
        label: "grow-0",
      },
      filterbuttonbar: "flex items-center justify-between p-5",
      filterclearbutton: {
        root: "w-auto min-[0px]:text-sm border-blue-500 text-blue-500 px-4 py-3",
      },
      filterapplybutton: {
        root: "w-auto min-[0px]:text-sm px-4 py-3",
      },
      filtermenubutton: ({ context }) => ({
        class: [
          "inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative ml-2",
          "w-8 h-8 rounded-[50%]",
          "transition duration-200",
          "hover:text-slate-700 hover:bg-slate-300/20", // Hover
          "focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", // Focus
          "dark:text-white/70 dark:hover:text-white/80 dark:bg-slate-900", // Dark Mode
          {
            "bg-blue-50 text-blue-700": context.active,
          },
        ],
      }),
      headerfilterclearbutton: ({ context }) => ({
        class: [
          "inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative",
          "text-left bg-transparent m-0 p-0 border-none select-none ml-2",
          {
            invisible: !context.hidden,
          },
        ],
      }),
      columnresizer: "block absolute top-0 right-0 m-0 w-2 h-full p-0 cursor-col-resize border border-transparent",
      rowreordericon: "cursor-move",
      roweditorinitbutton: {
        class: [
          "inline-flex items-center justify-center overflow-hidden relative",
          "text-left cursor-pointer select-none",
          "w-8 h-8 border-0 rounded-[50%]",
          "transition duration-200",
          "text-slate-700 border-transparent",
          "focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", //Focus
          "hover:text-slate-700 hover:bg-slate-300/20", //Hover
          "dark:text-white/70", // Dark Mode
        ],
      },
      roweditorsavebutton: {
        class: [
          "inline-flex items-center justify-center overflow-hidden relative",
          "text-left cursor-pointer select-none",
          "w-8 h-8 border-0 rounded-[50%]",
          "transition duration-200",
          "text-slate-700 border-transparent",
          "focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", //Focus
          "hover:text-slate-700 hover:bg-slate-300/20", //Hover
          "dark:text-white/70", // Dark Mode
        ],
      },
      roweditorcancelbutton: {
        class: [
          "inline-flex items-center justify-center overflow-hidden relative",
          "text-left cursor-pointer select-none",
          "w-8 h-8 border-0 rounded-[50%]",
          "transition duration-200",
          "text-slate-700 border-transparent",
          "focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", //Focus
          "hover:text-slate-700 hover:bg-slate-300/20", //Hover
          "dark:text-white/70", // Dark Mode
        ],
      },
      radiobuttonwrapper: {
        class: ["relative inline-flex cursor-pointer select-none align-bottom", "w-6 h-6"],
      },
      radiobutton: ({ context }) => ({
        class: [
          "flex justify-center items-center",
          "border-2 w-6 h-6 text-slate-700 rounded-full transition duration-200 ease-in-out",
          context.checked
            ? "border-blue-500 bg-blue-500 dark:border-blue-400 dark:bg-blue-400"
            : "border-slate-300 bg-white dark:border-blue-900/40 dark:bg-slate-900",
          {
            "hover:border-blue-500 dark:hover:border-blue-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]":
              !context.disabled,
            "cursor-default opacity-60": context.disabled,
          },
        ],
      }),
      radiobuttonicon: ({ context }) => ({
        class: [
          "transform rounded-full",
          "block w-3 h-3 transition duration-200 bg-white dark:bg-slate-900",
          {
            "backface-hidden scale-10 invisible": context.checked === false,
            "transform scale-100 visible": context.checked === true,
          },
        ],
      }),
      headercheckboxwrapper: {
        class: ["cursor-pointer inline-flex relative select-none align-bottom", "w-6 h-6"],
      },
      headercheckbox: ({ context }) => ({
        class: [
          "flex items-center justify-center",
          "border-2 w-6 h-6 text-slate-600 rounded-lg transition-colors duration-200",
          context.checked
            ? "border-blue-500 bg-blue-500 dark:border-blue-400 dark:bg-blue-400"
            : "border-slate-300 bg-white dark:border-blue-900/40 dark:bg-slate-900",
          {
            "hover:border-blue-500 dark:hover:border-blue-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]":
              !context.disabled,
            "cursor-default opacity-60": context.disabled,
          },
        ],
      }),
      headercheckboxicon: "w-4 h-4 transition-all duration-200 text-white text-base dark:text-slate-900",
      checkboxwrapper: {
        class: ["cursor-pointer inline-flex relative select-none align-bottom", "w-6 h-6"],
      },
      checkbox: ({ context }) => ({
        class: [
          "flex items-center justify-center",
          "border-2 w-6 h-6 text-slate-600 rounded-lg transition-colors duration-200",
          context.checked
            ? "border-blue-500 bg-blue-500 dark:border-blue-400 dark:bg-blue-400"
            : "border-slate-300 bg-white dark:border-blue-900/40 dark:bg-slate-900",
          {
            "hover:border-blue-500 dark:hover:border-blue-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]":
              !context.disabled,
            "cursor-default opacity-60": context.disabled,
          },
        ],
      }),
      checkboxicon: "w-4 h-4 transition-all duration-200 text-white text-base dark:text-slate-900",
      transition: TRANSITIONS.overlay,
    },
    bodyrow: ({ context }) => ({
      class: [
        context.selected
          ? "bg-blue-50 text-blue-700 dark:bg-blue-300"
          : "bg-white text-slate-600 hover:bg-slate-50 dark:bg-slate-900 group",
        context.stripedRows
          ? context.index % 2 === 0
            ? "bg-white text-slate-600 dark:bg-slate-900"
            : "bg-blue-50/50 text-slate-600 hover:bg-slate-50 dark:bg-slate-950"
          : "",
        "transition duration-200 relative",
        // "focus:outline focus:outline-[0.15rem] focus:outline-blue-200 focus:outline-offset-[-0.15rem]", // Focus
        // "dark:text-white/80 dark:focus:outline dark:focus:outline-[0.15rem] dark:focus:outline-blue-300 dark:focus:outline-offset-[-0.15rem]", // Dark Mode
        {
          "cursor-pointer": context.selectable,
          "hover:bg-slate-300/20 hover:text-slate-600": context.selectable && !context.selected, // Hover
        },
      ],
    }),
    rowexpansion: "bg-white text-slate-600 dark:bg-slate-900 dark:text-white/80",
    rowgroupheader: {
      class: ["sticky z-[1]", "bg-white text-slate-600", "transition duration-200"],
    },
    rowgroupfooter: {
      class: ["sticky z-[1]", "bg-white text-slate-600", "transition duration-200"],
    },
    rowgrouptoggler: {
      class: [
        "text-left m-0 p-0 cursor-pointer select-none",
        "inline-flex items-center justify-center overflow-hidden relative",
        "w-8 h-8 text-slate-500 border-0 bg-transparent rounded-[50%]",
        "transition duration-200",
        "dark:text-white/70", // Dark Mode
      ],
    },
    rowgrouptogglericon: "inline-block w-4 h-4",
    resizehelper: "absolute hidden w-px z-10 bg-blue-500 dark:bg-blue-300",
  },
  paginator: {
    root: {
      class: [
        "flex items-center justify-center flex-wrap",
        "bg-white text-slate-500 border-0 px-4 py-2 rounded-md text-sm justify-content",
        "dark:bg-slate-900 dark:text-white/60 dark:border-blue-900/40", // Dark Mode
      ],
    },
    firstpagebutton: ({ context }) => ({
      class: [
        "relative inline-flex items-center justify-center user-none overflow-hidden leading-none",
        "border-0 text-slate-500  min-w-[3rem] h-12 m-[0.143rem] hover:bg-slate-300/20 rounded-full",
        "transition duration-200",
        "dark:text-white", //Dark Mode
        {
          "cursor-default pointer-events-none opacity-60": context.disabled,
          "focus:outline-none ": !context.disabled, // Focus
        },
      ],
    }),
    previouspagebutton: ({ context }) => ({
      class: [
        "relative inline-flex items-center justify-center user-none overflow-hidden leading-none",
        "border-0 text-slate-500 min-w-[3rem] h-12 m-[0.143rem] mr-2.5 hover:bg-slate-300/20 rounded-full",
        "transition duration-200",
        "dark:text-white", //Dark Mode
        {
          "cursor-default pointer-events-none opacity-60": context.disabled,
          "focus:outline-none": !context.disabled, // Focus
        },
      ],
    }),
    nextpagebutton: ({ context }) => ({
      class: [
        "relative inline-flex items-center justify-center user-none overflow-hidden leading-none",
        "border-0 text-slate-500 min-w-[3rem] h-12 m-[0.143rem] ml-2.5 hover:bg-slate-300/20 rounded-full",
        "transition duration-200",
        "dark:text-white", //Dark Mode
        {
          "cursor-default pointer-events-none opacity-60": context.disabled,
          "focus:outline-none": !context.disabled, // Focus
        },
      ],
    }),
    lastpagebutton: ({ context }) => ({
      class: [
        "relative inline-flex items-center justify-center user-none overflow-hidden leading-none",
        "border-0 text-slate-500 min-w-[3rem] h-12 m-[0.143rem] hover:bg-slate-300/20 rounded-full",
        "transition duration-200",
        "dark:text-white", //Dark Mode
        {
          "cursor-default pointer-events-none opacity-60": context.disabled,
          "focus:outline-none": !context.disabled, // Focus
        },
      ],
    }),
    pagebutton: ({ context }) => ({
      class: [
        "relative inline-flex items-center justify-center user-none overflow-hidden leading-none",
        "border-0 text-slate-500 min-w-[3rem] h-12 m-[0.143rem] rounded-md",
        "transition duration-200",
        "dark:border-blue-300 dark:text-white", // Dark Mode
        "focus:outline-none", // Focus
        {
          "bg-blue-50 border-blue-50 text-blue-700 dark:bg-blue-300": context.active,
        },
      ],
    }),
    rowperpagedropdown: {
      root: ({ props, state }) => ({
        class: [
          "inline-flex relative cursor-pointer user-none",
          "bg-white border rounded-md",
          "transition duration-200",
          "h-12 mx-2",
          "dark:bg-slate-950 dark:border-blue-900/40", //DarkMode
          {
            "outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] border-blue-500":
              state.focused && !props.disabled, //Focus
            "border-slate-300": !state.focused,
            "hover:border-blue-500": !props.disabled, //Hover
          },
        ],
      }),
      input: {
        class: [
          "font-sans text-base text-slate-600 p-3 m-0 rounded-md apperance-none",
          "block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0 pr-0",
          "focus:outline-none focus:outline-offset-0",
          "dark:text-white", //Dark Mode
        ],
      },
      trigger: {
        class: ["flex items-center justify-center shrink-0", "text-slate-500 dark:text-white w-12 rounded-r-md"],
      },
      panel: {
        class: [
          "bg-white text-slate-600 border-0 rounded-md shadow-[0_2px_12px_rgba(0,0,0,0.1)]",
          "dark:bg-slate-900 dark:text-white/80 dark:border-blue-900/40", //Dark Mode
        ],
      },
      wrapper: "overflow-auto",
      list: "m-0 p-0 py-3 list-none",
      item: ({ context }) => ({
        class: [
          "relative font-normal cursor-pointer space-nowrap overflow-hidden",
          "m-0 py-3 px-5 border-none text-slate-600 rounded-none",
          "transition duration-200",
          "dark:text-white/80", // Dark Mode
          {
            "text-blue-700 bg-blue-50 dark:text-white/80 dark:bg-blue-300": !context.focused && context.selected,
            "bg-blue-300/40": context.focused && context.selected,
            "text-slate-600 bg-slate-300 dark:text-white/80 dark:bg-blue-900/40": context.focused && !context.selected,
          },
        ],
      }),
    },
    jumptopageinput: {
      root: "inline-flex mx-2",
      input: {
        class: [
          "font-sans text-base text-slate-600 p-3 m-0 rounded-md apperance-none",
          "block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border border-slate-300 pr-0",
          "focus:outline-none focus:outline-offset-0",
          "dark:text-white dark:bg-slate-950 dark:border-blue-900/40", //Dark Mode
          "m-0 flex-auto max-w-[3rem]",
        ],
      },
    },
    jumptopagedropdown: {
      root: ({ props, state }) => ({
        class: [
          "inline-flex relative cursor-pointer user-none",
          "bg-white border rounded-md",
          "transition duration-200",
          "h-12 mx-2",
          "dark:bg-slate-950 dark:border-blue-900/40", //DarkMode
          {
            "outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] border-blue-500":
              state.focused && !props.disabled, //Focus
            "border-slate-300": !state.focused,
            "hover:border-blue-500": !props.disabled, //Hover
          },
        ],
      }),
      input: {
        class: [
          "font-sans text-base text-slate-600 p-3 m-0 rounded-md apperance-none",
          "block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0 pr-0",
          "focus:outline-none focus:outline-offset-0",
          "dark:text-white", //Dark Mode
        ],
      },
      trigger: {
        class: ["flex items-center justify-center shrink-0", "text-slate-500 dark:text-white w-12 rounded-r-md"],
      },
      panel: {
        class: [
          "bg-white text-slate-600 border-0 rounded-md shadow-[0_2px_12px_rgba(0,0,0,0.1)]",
          "dark:bg-slate-900 dark:text-white/80 dark:border-blue-900/40", //Dark Mode
        ],
      },
      wrapper: "overflow-auto",
      list: "m-0 p-0 py-3 list-none",
      item: ({ context }) => ({
        class: [
          "relative font-normal cursor-pointer space-nowrap overflow-hidden",
          "m-0 py-3 px-5 border-none text-slate-600 rounded-none",
          "transition duration-200",
          "dark:text-white/80", // Dark Mode
          {
            "text-blue-700 bg-blue-50 dark:text-white/80 dark:bg-blue-300": !context.focused && context.selected,
            "bg-blue-300/40": context.focused && context.selected,
            "text-slate-600 bg-slate-300 dark:text-white/80 dark:bg-blue-900/40": context.focused && !context.selected,
          },
        ],
      }),
    },
  },
};
