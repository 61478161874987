import { defineStore } from "pinia";

export const useEventsStore = defineStore("events", {
  state: () => ({
    events: [],
  }),
  actions: {
    async fetchEvents() {
      const response = await axios.get(route("events.fetch"));
      this.events = response.data;
    },
  },
});
