<template>
  <div>
    <img v-if="props.user.image" :class="props.size" :src="props.user.image" alt="" class="rounded-full" />
    <span v-else :class="props.size" class="inline-flex items-center justify-center rounded-full bg-slate-500">
      <span :class="textSize" class="font-medium leading-none text-white">{{ initials }}</span>
    </span>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  user: Object,
  size: String,
  textSize: String,
});

const initials = computed(() => {
  return `${props.user.first_name?.charAt(0)}${props.user.last_name?.charAt(0)}`.toUpperCase();
});
</script>
