<template>
  <div v-if="loading && loadCount === 0" class="w-full h-full flex justify-center items-center">
    <PSpinningLoader class="h-24 w-24 text-slate-700"></PSpinningLoader>
  </div>
  <div v-else>
    <PHeader :class="headerClass">
      <slot name="header"></slot>
    </PHeader>
    <PContainer :class="containerClass">
      <slot></slot>
    </PContainer>
  </div>
</template>

<script setup>
import PHeader from "./PHeader.vue";
import PContainer from "./PContainer.vue";
import PSpinningLoader from "./PSpinningLoader.vue";
import { ref, toRefs, watch } from "vue-demi";

const props = defineProps({
  loading: { type: Boolean, default: false },
  headerClass: { type: String, default: "py-8 scroll-mt-8" },
  containerClass: { type: String, default: "" },
});
const { containerClass, headerClass, loading } = toRefs(props);
const loadCount = ref(0);

watch(
  () => loading.value,
  (newVal, oldVal) => {
    if (oldVal && !newVal) {
      loadCount.value++;
    }
  }
);
</script>
