export default function useStr() {
  const strToLower = (str) => {
    if (!str) return "";
    str = str.toString();
    return str.toLowerCase();
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return {
    strToLower,
    capitalize,
  };
}
